import { SOLID_ERROR_CODES_GROUPS } from 'sentry-utils';

const DEFAULT_REASON = 'SOLID_NOT_STANDARD_ERROR';

export const getErrorReasonGroup = (errorCode: string) => {
    let reason = DEFAULT_REASON;

    SOLID_ERROR_CODES_GROUPS.map(({ name, errorList }) => {
        if (errorList.includes(errorCode)) {
            reason = name;
        }
    });

    return reason;
};
