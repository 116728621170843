import { useEffect, useState } from 'react';

import { getCookie, setCookie } from 'helpers/cookie';

const COUNT_RETAKE_PHOTO = 5;
const COOKIE_NAME_RETAKE = 'countRetakePhoto';

const useCounterRetakePhoto = () => {
    const [countRetakePhoto, setCountRetakePhoto] = useState(getCookie(COOKIE_NAME_RETAKE) || COUNT_RETAKE_PHOTO);

    const handleCountRetakePhoto = (skipDecrement = false) => {
        const count = skipDecrement ? COUNT_RETAKE_PHOTO : Number(countRetakePhoto) - 1;

        if (count < 0) return;

        setCookie(COOKIE_NAME_RETAKE, count);

        setCountRetakePhoto(count);
    };

    useEffect(() => {
        setCookie(COOKIE_NAME_RETAKE, countRetakePhoto);
    }, [countRetakePhoto]);

    return {
        isDisabled: Number(countRetakePhoto) <= 0,
        countRetakePhoto,
        handleCountRetakePhoto,
    };
};

export { useCounterRetakePhoto };
