import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import { useNetworkStatus } from 'hooks';

import { Box, Button, Text } from 'libComponents';
import Modal from 'components/Modal';

import styles from './NoConnectionModal.module.css';

const NoConnectionModal = () => {
    const isOnline = useNetworkStatus();
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const handleClick = () => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    };

    return (
        <Modal isOpen={!isOnline} withClose={false} centeredModal networkModal>
            <Box paddingTop={8} paddingBottom={8}>
                <span role="img" aria-label="network" className={styles.mainImg}>
                    🌐
                </span>
                <Text type="h5" text={t('networkErrorModal.title')} center color="text-main" mb={12} />
                <Text text={t('networkErrorModal.subtitle')} center mb={32} />
                <Button
                    textColor="text-main"
                    backgroundColor="surface-main"
                    isLoading={isLoading}
                    onClick={handleClick}
                    className={styles.customButton}
                    hoverColor="surface-hovered"
                    text={t('basics.tryAgain')}
                />
            </Box>
        </Modal>
    );
};

export default NoConnectionModal;
