import { Main } from 'wikr-core-analytics';
import { Event } from '@sentry/react';

// TODO: after test add this to lib
const CSS_CHUNK_LOAD_FAILED = 'CSS_CHUNK_LOAD_FAILED';

export const chunkError = {
    loadingChunkError: 'Loading chunk',
    loadingCSSChunkError: 'Loading CSS chunk',
};

export const isOnline = () => {
    return navigator.onLine; // Check if the browser is online
};

export const isChunkLoadHighLevelError = (error: Event) => {
    return error.exception?.values?.some(({ value }) => value?.includes(chunkError.loadingCSSChunkError));
};

const isChunkError = (event: Event) => {
    const isHighLevel = isChunkLoadHighLevelError(event);
    const isAppLevel =
        event?.message?.includes(chunkError.loadingChunkError) ||
        event?.message?.includes(chunkError.loadingCSSChunkError);

    return { isHighLevel, isAppLevel, hasError: isHighLevel || isAppLevel };
};

const getAnalyticData = async () => {
    const isInitAmazonAnalytic = Main.checkIsAlreadyInited({ name: 'amazon' });

    return (isInitAmazonAnalytic && (await Main?.getDefaultAmazonAnalyticPurchaseData?.())) || {};
};

export const setCustomTags = async (event: Event): Promise<Event> => {
    const customEvent = { ...event };
    const analyticData = await getAnalyticData();

    if (customEvent) {
        customEvent.tags = { ...customEvent?.tags, IS_ONLINE: isOnline() };

        const { hasError, isHighLevel } = isChunkError(customEvent);

        if (hasError) {
            customEvent.tags = { ...customEvent?.tags, ERROR_TYPE: CSS_CHUNK_LOAD_FAILED };
            if (isHighLevel) {
                customEvent.extra = { ...customEvent?.extra, ...analyticData };
            }

            console.error('CSS_CHUNK_LOAD_FAILED', customEvent);
        }
    }

    return customEvent;
};
