import { IButton } from 'wikr-core-components';
import classNames from 'classnames/bind';

import { Button } from 'libComponents';

import styles from './OutlinedButton.module.css';

const cx = classNames.bind(styles);

const OutlinedButton = ({ text, onClick, dataLocator, mb, size = 'small' }: IButton) => {
    const btnStyles = cx('outlinedButton', {
        copyBtn: size === 'small',
    });

    return (
        <Button
            text={text}
            onClick={onClick}
            mb={mb}
            size={size}
            textColor="text-main"
            dataLocator={dataLocator}
            className={btnStyles}
        />
    );
};

export default OutlinedButton;
