import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const selectOnboarding = (state: RootState) => state.onboarding;

const selectGender = createSelector(selectOnboarding, (onboarding) => onboarding.gender);
const selectActivityLevel = createSelector(selectOnboarding, (onboarding) => onboarding.activityLevel);
const selectAge = createSelector(selectOnboarding, (onboarding) => onboarding.age);
const selectCurrentBody = createSelector(selectOnboarding, (onboarding) => onboarding.current_body);
const selectCurrentWeight = createSelector(selectOnboarding, (onboarding) => onboarding.current_weight);
const selectFitnessLevel = createSelector(selectOnboarding, (onboarding) => onboarding.fitness_level);
const selectGoal = createSelector(selectOnboarding, (onboarding) => onboarding.goal);
const selectMeasureSystem = createSelector(selectOnboarding, (onboarding) => onboarding.measureSystem);
const selectOnboardingScreensList = createSelector(selectOnboarding, (onboarding) => onboarding.onboardingScreensList);
const selectTall = createSelector(selectOnboarding, (onboarding) => onboarding.tall);
const selectTargetWeight = createSelector(selectOnboarding, (onboarding) => onboarding.target_weight);
const selectTargetZone = createSelector(selectOnboarding, (onboarding) => onboarding.target_zone);
const selectUrlParams = createSelector(selectOnboarding, (onboarding) => onboarding.urlParams);
const selectTargetBodyType = createSelector(selectOnboarding, (onboarding) => onboarding.target_bodytype);
const selectFastingMotivation = createSelector(selectOnboarding, (onboarding) => onboarding.fastingMotivation);
const selectDinnerTime = createSelector(selectOnboarding, (onboarding) => onboarding.dinnerTime);
const selectLunchTime = createSelector(selectOnboarding, (onboarding) => onboarding.lunchTime);
const selectBreakfastTime = createSelector(selectOnboarding, (onboarding) => onboarding.breakfastTime);
const selectLifeStyle = createSelector(selectOnboarding, (onboarding) => onboarding.lifeStyle);
const selectFastingFamiliarity = createSelector(selectOnboarding, (onboarding) => onboarding.fastingFamiliarity);
const selectFastingLevel = createSelector(selectOnboarding, (onboarding) => onboarding.fastingLevel);
const selectOccasion = createSelector(selectOnboarding, (onboarding) => onboarding.occasion);
const selectJobActive = createSelector(selectOnboarding, (onboarding) => onboarding.jobActive);
const selectAgeGroup = createSelector(selectOnboarding, (onboarding) => onboarding.ageGroup);
const selectFeelings = createSelector(selectOnboarding, (onboarding) => onboarding.feelings);
const selectDiet = createSelector(selectOnboarding, (onboarding) => onboarding.diet);
const selectExercise = createSelector(selectOnboarding, (onboarding) => onboarding.exercise);
const selectIsTakeYourPhotoFlow = createSelector(selectOnboarding, (onboarding) =>
    Boolean(
        Number(onboarding.current_weight) - Number(onboarding.target_weight) >= 10 &&
            (Array.isArray(onboarding.goal)
                ? onboarding.goal.includes('lose_weight')
                : onboarding.goal === 'lose_weight')
    )
);
const selectIsTakeYourPhotoFlowLessDiff = createSelector(selectOnboarding, (onboarding) =>
    Boolean(
        Number(onboarding.current_weight) - Number(onboarding.target_weight) >= 5 &&
            (Array.isArray(onboarding.goal)
                ? onboarding.goal.includes('lose_weight')
                : onboarding.goal === 'lose_weight')
    )
);

export {
    selectOnboarding,
    selectGender,
    selectCurrentWeight,
    selectActivityLevel,
    selectAge,
    selectCurrentBody,
    selectFitnessLevel,
    selectGoal,
    selectMeasureSystem,
    selectOnboardingScreensList,
    selectTall,
    selectTargetWeight,
    selectTargetZone,
    selectUrlParams,
    selectTargetBodyType,
    selectFastingMotivation,
    selectDinnerTime,
    selectLunchTime,
    selectBreakfastTime,
    selectLifeStyle,
    selectFastingFamiliarity,
    selectFastingLevel,
    selectOccasion,
    selectJobActive,
    selectAgeGroup,
    selectFeelings,
    selectDiet,
    selectExercise,
    selectIsTakeYourPhotoFlow,
    selectIsTakeYourPhotoFlowLessDiff,
};
