import { useSelector, useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import { Trans } from 'react-i18next';

import { selectUIEffects } from 'redux/UiEffects/selectors';
import { hideModal } from 'redux/UiEffects/actions';

import { getProjectEmail } from 'helpers/utilsUpdated';

import Title from 'components/Title';
import Text from 'components/Text';
import ModalClose from 'components/ModalClose/ModalClose';
import Button from 'components/Button';

import styles from './NotificationModal.module.css';

import { ReactComponent as IconError } from './img/issueProblem.svg';
import { ReactComponent as IconInfo } from './img/issueInfo.svg';

ReactModal.setAppElement('#app');

function NotificationModal() {
    const dispatch = useDispatch();

    const { issueType, issueDescription, issueTitle, modalIsOpen, onClose } = useSelector(selectUIEffects);

    const handleClose = () => {
        dispatch(hideModal());
        onClose && onClose();
    };

    const isErrorMessage = issueType === 'ERROR';

    return (
        <ReactModal
            shouldCloseOnEsc={false}
            isOpen={modalIsOpen}
            onRequestClose={handleClose}
            contentLabel="Modal"
            className={styles.notificationModal}
            overlayClassName={styles.Overlay}
            bodyOpenClassName={styles.ReactModalBodyOpen}
        >
            <ModalClose onClick={handleClose} notificationModalIcon />

            {isErrorMessage ? (
                <IconError className={styles.notificationModalIcon} />
            ) : (
                <IconInfo className={styles.notificationModalIcon} />
            )}

            <Title h3>
                <Trans>{issueTitle}</Trans>
            </Title>
            <Text small grey globalClassNames={['mb-s']}>
                <Trans>{issueDescription}</Trans>
            </Text>
            <Text small globalClassNames={['colorBrand', 'mb-m']}>
                <a href={'mailto:' + getProjectEmail()}>{getProjectEmail()}</a>
            </Text>
            <Button onClick={handleClose} primary notificationBtn globalClassNames={['mb-l']} title="GOT IT" />
        </ReactModal>
    );
}

export default NotificationModal;
