/* eslint-disable max-lines */
import { PaymentMethod } from 'types/payments/payments';

export const BANK_CARD_CUSTOM_BUTTON_ID = 'customSubmitButton';

export const GOOGLE_PAY_OPTION = 'google_pay';
export const CREDIT_CARD_OPTION = 'Bank_Card';
export const PAYPAL_OPTION = 'PayPal';
export const APPLE_PAY_OPTION = 'apple_pay';

export const DAYS_PER_YEAR = 365;
export const DAYS_PER_MONTH = 31;
export const DAYS_PER_WEEK = 7;
export const DAYS_PER_TWO_MONTH = 1;
export const DAYS_PER_THREE_MONTH = 90;
export const DAYS_PER_FOUR_MONTH = 121;
export const DAYS_PER_FIVE_MONTH = 152;
export const DAYS_PER_SIX_MONTH = 182;
export const DAYS_PER_SEVEN_MONTH = 212;
export const DAYS_PER_EIGHT_MONTH = 243;
export const DAYS_PER_NINE_MONTH = 273;
export const DAYS_PER_TEN_MONTH = 304;
export const DAYS_PER_ELEVEN_MONTH = 334;
export const DAYS_PER_ONE_YEAR = 365;

export const APP_FULL_ACCESS = 'app_full_access';
export const VIP_SUPPORT = 'vip_support';

export interface IPaymentTypes {
    CREDIT_CARD: number;
    GOOGLE_PAY: number;
    PAYPAL: number;
    APPLE_PAY: number;
}

export const PAYMENT_TYPES: IPaymentTypes = {
    CREDIT_CARD: 1,
    GOOGLE_PAY: 1,
    PAYPAL: 4,
    APPLE_PAY: 1,
};

export const PAYMENT_TYPES_NAME = {
    CREDIT_CARD: CREDIT_CARD_OPTION,
    PAYPAL: PAYPAL_OPTION,
    APPLE_PAY: APPLE_PAY_OPTION,
    GOOGLE_PAY: GOOGLE_PAY_OPTION,
};

export const PAYMENT_METHOD_NAME = {
    1: CREDIT_CARD_OPTION,
    2: GOOGLE_PAY_OPTION,
    3: 'ApplePay',
    4: PAYPAL_OPTION,
};

export const PAYMENT_TYPES_NAME_ID = {
    CREDIT_CARD: 'bankCard',
    PAYPAL: 'payPal',
    APPLE_PAY: 'paymentGenerator',
    GOOGLE_PAY: 'paymentGenerator',
};

export const CREDIT_CARD_LITERAL = 'CREDIT_CARD';
export const PAYPAL_LITERAL = 'PAYPAL';
export const GOOGLE_PAY_LITERAL = 'GOOGLE_PAY';
export const APPLE_PAY_LITERAL = 'APPLE_PAY';
export const ONE_CLICK_PAYMENT_OPTION = 'ONE_CLICK';

export const PRESELECTED_PAYMENT_TYPE: Record<string, PaymentMethod> = {
    apple_pay: APPLE_PAY_LITERAL,
    google_pay: GOOGLE_PAY_LITERAL,
    bank_card: CREDIT_CARD_LITERAL,
    paypal: PAYPAL_LITERAL,
};

export const PAYMENT_DEFAULT_CURRENCY = {
    sign: '$',
    name: 'USD',
};

export const EURO = {
    sign: '€',
    name: 'EUR',
};

export const GBP = {
    sign: '£',
    name: 'GBP',
};

export const BRL = {
    sign: 'R$',
    name: 'BRL',
};

export const MXN = {
    sign: 'M$',
    name: 'MXN',
};

export const LATAM_SPECIAL_MEX_CURRENCY = {
    sign: 'MEX$',
    name: 'MXN',
};

export const CLP = {
    sign: 'CLP',
    name: 'CLP',
};

export const COP = {
    sign: 'COL$',
    name: 'COP',
};

export const PEN = {
    sign: 'S/',
    name: 'PEN',
};

export const DEVICE_OS = {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
};

export const PAYMENT_FLOW_PERIODS = [
    DAYS_PER_MONTH,
    DAYS_PER_TWO_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_FOUR_MONTH,
    DAYS_PER_FIVE_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_SEVEN_MONTH,
    DAYS_PER_EIGHT_MONTH,
    DAYS_PER_NINE_MONTH,
    DAYS_PER_TEN_MONTH,
    DAYS_PER_ELEVEN_MONTH,
    DAYS_PER_YEAR,
    DAYS_PER_WEEK,
    DAYS_PER_ONE_YEAR,
];

// Introductory flow
export const INTRODUCTORY_A_FLOW_PAYMENT_ID = 'payment_Aa_checkout_Alpha1';
export const INTRODUCTORY_B_FLOW_PAYMENT_ID = 'payment_Ab_Checkout_Alpha1';

export const MINIMUM_NUMBER_OF_PRODUCTS_TO_DISPLAY_THE_TIMER = 6;

export const LTV_COEFFICIENT_KEY = 'ltv_coef';

export const DEFAULT_LTV_COEFFICIENT = 1;

export const DEFAULT_TRIAL_PRICE = 30.99;
