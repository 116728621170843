import * as action from './actions';

import * as actionTypes from './actionTypes';

import { InferValueTypes } from 'types/commonInterfaces';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState = {
    isLoading: false,
    isSendPhotoSuccess: false,
    isSendPhotoError: false,
    photo: '',
};

const VundervaflaReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SAVE_PHOTO:
            return {
                ...state,
                photo: action.payload,
            };

        default:
            return state;
    }
};

export default VundervaflaReducer;
