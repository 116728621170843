/* eslint-disable max-lines */
/* eslint-disable quotes */
import { systemNames } from 'wikr-core-analytics';
import { ReactSVGElement } from 'react';
import { GENERAL_GO5_FLOW, GL_CALORIEDEFICIT_GO1_FLOW } from 'constants/testania';
import { isOneMonthProduct } from './testania';

import {
    LTV_COEFFICIENT_KEY,
    DEFAULT_LTV_COEFFICIENT,
    DAYS_PER_EIGHT_MONTH,
    DAYS_PER_FIVE_MONTH,
    DAYS_PER_FOUR_MONTH,
    DAYS_PER_MONTH,
    DAYS_PER_SEVEN_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_TWO_MONTH,
    DAYS_PER_WEEK,
    DAYS_PER_ONE_YEAR,
} from 'constants/payments';
import { IMPERIAL } from 'constants/measureUnits';

import { PurchaseOption } from 'services/Analytics/types';

import { isMaleGender, getPriceFromCents } from 'helpers/utilsUpdated';
import Convert from 'helpers/Convert';
import { excludeAnalyticsSystems } from 'helpers/analytics';

import { FoxtrotBodyFields, FoxtrotBodyType } from 'pages/PaymentLandingPages/PaymentG/types';

import { CurrentProduct } from 'types/payments/payments';
import { IGender } from 'types/commonInterfaces';

type getBeforeAfterParametersType = {
    currentBody: string | null;
    targetBody: string | null;
    gender: IGender;
    CURRENT_BODY: FoxtrotBodyType;
    TARGET_BODY: FoxtrotBodyType;
    isImgFromTestania?: boolean;
    testaniaConfig?: Record<string, any>;
};

type getPlanContentParametersType = {
    targetWeight: number;
    measureSystem: string;
    fastingLevel: string | null;
    fitnessLevel?: string | null;
    statisticLabel?: string;
    iconSet: {
        beginnerIcon: () => ReactSVGElement;
        intermediateIcon: () => ReactSVGElement;
        advancedIcon: () => ReactSVGElement;
    };
};

type GetPurchaseOptionsType = ({
    period,
    trial,
    analytics,
}: Partial<CurrentProduct> & {
    analytics: string[];
}) => PurchaseOption;

interface GetContentIdProps {
    product: CurrentProduct;
    price: number;
    trialPrice: number;
}

const DEFAULT_CURRENT_BODY_M = 'Slightly overweight';
const DEFAULT_CURRENT_BODY_F = 'Plump';
const DEFAULT_TARGET_BODY_M = 'bulk';
const DEFAULT_TARGET_BODY_F = 'fit';

export const DEFAULT_BEFORE_MALE_IMG_FROM_TESTANIA =
    'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/monetisation/male_before_3.webp';

export const DEFAULT_BEFORE_FEMALE_IMG_FROM_TESTANIA =
    'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/monetisation/female_before.webp';
export const DEFAULT_AFTER_MALE_IMG_FROM_TESTANIA =
    'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/monetisation/male_after_3.webp';

export const DEFAULT_AFTER_FEMALE_IMG_FROM_TESTANIA =
    'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/monetisation/female_after.webp';

export const getBeforeAfterContent = ({
    currentBody,
    targetBody,
    gender,
    CURRENT_BODY,
    TARGET_BODY,
    isImgFromTestania = false,
    testaniaConfig,
}: getBeforeAfterParametersType): FoxtrotBodyFields[] => {
    const isMale = isMaleGender(gender);

    const currentBodyDefault: string = currentBody || (isMale ? DEFAULT_CURRENT_BODY_M : DEFAULT_CURRENT_BODY_F);
    const targetBodyDefault: string = targetBody || (isMale ? DEFAULT_TARGET_BODY_M : DEFAULT_TARGET_BODY_F);

    let currentData, targetData;

    switch (currentBodyDefault) {
        case 'Average':
            currentData = isMale ? CURRENT_BODY.averageM : CURRENT_BODY.averageF;
            break;

        case 'Plump':
            currentData = CURRENT_BODY.plump;
            break;

        case 'Extra':
            currentData = CURRENT_BODY.extra;
            break;

        case 'Slightly overweight':
            currentData = CURRENT_BODY.slightlyOverweight;
            break;

        case 'Overweight':
            currentData = CURRENT_BODY.overweight;
            break;

        default:
            currentData = {} as FoxtrotBodyFields;
    }

    switch (targetBodyDefault) {
        case 'fit':
            targetData = isMale ? TARGET_BODY.fitM : TARGET_BODY.fitF;
            break;

        case 'athletic':
            targetData = TARGET_BODY.athletic;
            break;

        case 'shapely':
            targetData = TARGET_BODY.shapely;
            break;

        case 'cut':
            targetData = TARGET_BODY.cut;
            break;

        case 'bulk':
            targetData = TARGET_BODY.bulk;
            break;

        default:
            targetData = {} as FoxtrotBodyFields;
    }

    if (isImgFromTestania && testaniaConfig) {
        if (testaniaConfig.maleModelImageBefore && testaniaConfig.femaleModelImageBefore) {
            currentData.img = isMale ? testaniaConfig.maleModelImageBefore : testaniaConfig.femaleModelImageBefore;
        } else {
            currentData.img = isMale ? DEFAULT_BEFORE_MALE_IMG_FROM_TESTANIA : DEFAULT_BEFORE_FEMALE_IMG_FROM_TESTANIA;
        }

        if (testaniaConfig.maleModelImageAfter && testaniaConfig.femaleModelImageAfter) {
            targetData.img = isMale ? testaniaConfig.maleModelImageAfter : testaniaConfig.femaleModelImageAfter;
        } else {
            targetData.img = isMale ? DEFAULT_AFTER_MALE_IMG_FROM_TESTANIA : DEFAULT_AFTER_FEMALE_IMG_FROM_TESTANIA;
        }
    }

    return [currentData, targetData];
};

export const getPlanContent = ({
    targetWeight,
    measureSystem,
    fastingLevel,
    iconSet,
    fitnessLevel,
    statisticLabel,
}: getPlanContentParametersType) => {
    let weightResult;
    let measureUnit;
    let fastingLevelResult, fastingLevelIcon;

    if (measureSystem === IMPERIAL) {
        const convertUnits = new Convert();

        weightResult = convertUnits.fromKgToLbs(targetWeight);
        measureUnit = 'measureUnits.lbs';
    } else {
        weightResult = targetWeight;
        measureUnit = 'measureUnits.kg';
    }

    switch (fastingLevel) {
        case 'Only the name':
            fastingLevelResult = 'paymentFlow.paymentBa.planReady.fastingLevel.beginner';
            fastingLevelIcon = () => iconSet.beginnerIcon();
            break;

        case 'A couple of things':
            fastingLevelResult = 'paymentFlow.paymentBa.planReady.fastingLevel.intermediate';
            fastingLevelIcon = () => iconSet.intermediateIcon();
            break;

        case "I'm experienced in fasting":

        case "I'm experienced in keto":

        case "I'm experienced in water fasting":
            fastingLevelResult = 'paymentFlow.paymentBa.planReady.fastingLevel.advanced';
            fastingLevelIcon = () => iconSet.advancedIcon();
            break;

        default:
            fastingLevelResult = '';
            fastingLevelIcon = () => {};
    }

    if (statisticLabel === 'fitness') {
        switch (fitnessLevel) {
            case "I'm just getting started":
                fastingLevelResult = 'paymentFlow.paymentBa.planReady.fastingLevel.beginner';
                fastingLevelIcon = () => iconSet.beginnerIcon();
                break;

            case '1-2 workouts a week':
                fastingLevelResult = 'paymentFlow.paymentBa.planReady.fastingLevel.intermediate';
                fastingLevelIcon = () => iconSet.intermediateIcon();
                break;

            case '3-5 times a week':
                fastingLevelResult = 'paymentFlow.paymentBa.planReady.fastingLevel.advanced';
                fastingLevelIcon = () => iconSet.advancedIcon();
                break;

            default:
                fastingLevelResult = '';
                fastingLevelIcon = () => {};
        }
    }

    return { weightResult, measureUnit, fastingLevelResult, fastingLevelIcon };
};

export const ICUPeriodNormalizer = (period: number, isCustom = false): { count: number; context: string } => {
    let normalizedPeriod = { count: 1, context: 'period' };

    switch (period) {
        case DAYS_PER_WEEK:
            normalizedPeriod = isCustom ? { count: 7, context: 'day' } : { count: 1, context: 'week' };
            break;

        case DAYS_PER_MONTH:
            normalizedPeriod = isCustom ? { count: 4, context: 'week' } : { count: 1, context: 'month' };
            break;

        case DAYS_PER_TWO_MONTH:
            normalizedPeriod = isCustom ? { count: 8, context: 'week' } : { count: 2, context: 'month' };
            break;

        case DAYS_PER_THREE_MONTH:
            normalizedPeriod = isCustom ? { count: 13, context: 'week' } : { count: 3, context: 'month' };
            break;

        case DAYS_PER_FOUR_MONTH:
            normalizedPeriod = isCustom ? { count: 17, context: 'week' } : { count: 4, context: 'month' };
            break;

        case DAYS_PER_FIVE_MONTH:
            normalizedPeriod = isCustom ? { count: 22, context: 'week' } : { count: 5, context: 'month' };
            break;

        case DAYS_PER_SIX_MONTH:
            normalizedPeriod = isCustom ? { count: 26, context: 'week' } : { count: 6, context: 'month' };
            break;

        case DAYS_PER_SEVEN_MONTH:
            normalizedPeriod = isCustom ? { count: 30, context: 'week' } : { count: 7, context: 'month' };
            break;

        case DAYS_PER_EIGHT_MONTH:
            normalizedPeriod = isCustom ? { count: 35, context: 'week' } : { count: 8, context: 'month' };
            break;

        case DAYS_PER_ONE_YEAR:
            normalizedPeriod = isCustom ? { count: 52, context: 'week' } : { count: 1, context: 'year' };
            break;
    }

    return normalizedPeriod;
};

export const calculateLTV = (productLTV: number, productPeriod: number) => {
    const coefficient =
        productPeriod !== DAYS_PER_MONTH
            ? DEFAULT_LTV_COEFFICIENT
            : Number(localStorage.getItem(LTV_COEFFICIENT_KEY)) || DEFAULT_LTV_COEFFICIENT;

    return getPriceFromCents(Number(productLTV) * coefficient);
};

export const getStartPriceFromProductWithoutTrial = ({
    products,
    trialProductPeriod,
    defaultTrialPrice,
}: {
    products: CurrentProduct[];
    trialProductPeriod: number;
    defaultTrialPrice: number;
}) => {
    const productWithoutTrial = products.find(
        (product) => product.period === trialProductPeriod && product.trial === trialProductPeriod
    );

    return getPriceFromCents(Number(productWithoutTrial?.start_price)) || defaultTrialPrice;
};

export const getAnalyticsSystems: GetPurchaseOptionsType = ({ period, trial, analytics }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const testName = urlParams.get('test-name') || '';
    const branchName = urlParams.get('branch-name') || '';

    const oneMonthProduct = isOneMonthProduct({ period, trial });

    const generalGo5Flow = branchName === GENERAL_GO5_FLOW.branchName && testName === GENERAL_GO5_FLOW.testName;
    const glCalorieDeficitGo1Flow =
        branchName === GL_CALORIEDEFICIT_GO1_FLOW.branchName && testName === GL_CALORIEDEFICIT_GO1_FLOW.testName;

    if (oneMonthProduct && generalGo5Flow)
        return {
            selectedSystems: excludeAnalyticsSystems([systemNames.pixel]),
        };

    if (oneMonthProduct && glCalorieDeficitGo1Flow)
        return {
            selectedSystems: excludeAnalyticsSystems([systemNames.ga, systemNames.ga4]),
        };

    return {
        selectedSystems: analytics,
    };
};

export const getContentId = ({ product, price, trialPrice }: GetContentIdProps) => {
    const { payment_type, product_code, trial, period } = product;

    const periodValue = `period: ${trial}/${period}`;

    const priceValue = `price: ${trialPrice}/${price}`;

    return `${payment_type}: ${product_code} | ${periodValue} | ${priceValue}`;
};
