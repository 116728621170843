import ApiClient from 'services/ApiClient';

import { VundervaflaData, VundervaflaResponse } from 'types/vundervafla/apiInterfaces';

class VundervaflaApi {
    async vundervafla<Data = VundervaflaData, Response = VundervaflaResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await ApiClient.post<Data, Response>('vundervafla/', payload, {
            headers: {
                'Content-Type': 'text/plain;charset=UTF-8',
            },
        });
    }
}

export default VundervaflaApi;
