import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import api from 'api';

import { selectVundervaflaPhoto } from 'redux/Vundervafla/selectors';
import { saveUserPhoto } from 'redux/Vundervafla/actions';

import { convertBase64 } from 'helpers/base64Helper';

import imageCompression, { Options } from 'browser-image-compression';

enum PhotoStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCESS_SAVE = 'successSave',
    ERROR_SAVE = 'errorSave',
    SUCCESS_SEND = 'successSend',
    ERROR_SEND = 'errorSend',
    FORMAT_ERROR = 'formatError',
}

const DEFAULT_IMG_OPTIONS: Options = {
    maxSizeMB: 2,
    maxWidthOrHeight: 1920,
};

const useUserPhoto = (options = DEFAULT_IMG_OPTIONS) => {
    const [status, setStatus] = useState(PhotoStatus.IDLE);

    const dispatch = useDispatch();
    const photo = useSelector(selectVundervaflaPhoto);

    const handleChange = async (
        e: React.ChangeEvent<HTMLInputElement>,
        { onSuccess, onError }: { onSuccess?: (photo: unknown | string) => void; onError?: (err: any) => void } = {}
    ) => {
        try {
            if (!e.target.files || !e.target.files[0]?.type.includes('image/')) {
                setStatus(PhotoStatus.FORMAT_ERROR);

                return;
            }

            setStatus(PhotoStatus.LOADING);

            const compressedFile = await imageCompression(e.target.files[0], options);

            const base64 = await convertBase64(compressedFile);

            dispatch(saveUserPhoto(base64));

            setStatus(PhotoStatus.SUCCESS_SAVE);

            onSuccess && onSuccess(base64);
        } catch (e) {
            setStatus(PhotoStatus.ERROR_SAVE);
            onError && onError(e);
        }
    };

    const handleSendPhoto = async (img?: unknown | string) => {
        try {
            setStatus(PhotoStatus.LOADING);

            const response = await api.vundervafla.vundervafla(img || photo);

            if (response) {
                setStatus(PhotoStatus.SUCCESS_SEND);
            }
        } catch (e) {
            setStatus(PhotoStatus.ERROR_SEND);
        }
    };

    return {
        photo,
        isLoading: status === PhotoStatus.LOADING,
        isSuccessSend: status === PhotoStatus.SUCCESS_SEND,
        isErrorSend: status === PhotoStatus.ERROR_SEND,
        isSuccessSave: status === PhotoStatus.SUCCESS_SAVE,
        isErrorSave: status === PhotoStatus.ERROR_SAVE,
        isFormatError: status === PhotoStatus.FORMAT_ERROR,
        handleSendPhoto,
        handleChange,
    };
};

export { useUserPhoto };
