import { Switcher as LibSwitcher } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, FC } from 'react';

interface ISwitcherCustom {
    data: Array<string>;
    onChange: (e: ChangeEvent) => void;
    defaultUnit?: string;
    rounded?: boolean;
    mb?: number;
    dataLocators?: Array<string>;
    className?: Record<string, unknown> & string;
}

const Switcher: FC<ISwitcherCustom> = ({ data, defaultUnit, rounded, onChange, mb, dataLocators, className }) => {
    const { t } = useTranslation();

    const translatedData = data && data.map((item) => t(item));

    return (
        <LibSwitcher
            data={data}
            translatedData={translatedData}
            defaultUnit={defaultUnit}
            rounded={rounded}
            onChange={onChange}
            mb={mb}
            dataLocators={dataLocators}
            className={className}
        />
    );
};

export { Switcher };
