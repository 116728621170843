import { APP_FULL_ACCESS } from './payments';
import {
    BRAZILIAN_COUNTRY_CODE,
    CHILEAN_COUNTRY_CODE,
    COLOMBIAN_COUNTRY_CODE,
    MEXICAN_COUNTRY_CODE,
    PERUVIAN_COUNTRY_CODE,
} from './countriesList';

export const EXCLUSIVE_OFFER_PRODUCT = {
    product_code: APP_FULL_ACCESS,
    payment_type: 'subscription',
    id: '1ed6c266-c1ba-4e03-9090-1353b7e71f52',
    name: 'One-week intro (0.99) - Three-months (29.99)',
    trial: 7,
    period: 90,
    price_per_state: 1,
    start_price_per_state: 1,
    is_preselected: false,
    product_state: 'per_day',
    ltv: 1500,
};

export const EXCLUSIVE_OFFER_PRODUCT_DEFAULT = {
    price: 99,
    start_price: 2999,
};

export const EXCLUSIVE_OFFER_PRODUCT_BRAZIL = {
    price: 495,
    start_price: 15000,
};

export const EXCLUSIVE_OFFER_PRODUCT_MEXICO = {
    price: 1790,
    start_price: 54000,
};

export const EXCLUSIVE_OFFER_PRODUCT_CHILI = {
    price: 92500,
    start_price: 2788800,
};

export const EXCLUSIVE_OFFER_PRODUCT_COLOMBIA = {
    price: 413000,
    start_price: 12412000,
};

export const EXCLUSIVE_OFFER_PRODUCT_PERU = {
    price: 380,
    start_price: 11600,
};

export const PRICE_BY_COUNTRY: Record<string, { price: number; start_price: number }> = {
    [BRAZILIAN_COUNTRY_CODE]: EXCLUSIVE_OFFER_PRODUCT_BRAZIL,
    [MEXICAN_COUNTRY_CODE]: EXCLUSIVE_OFFER_PRODUCT_MEXICO,
    [CHILEAN_COUNTRY_CODE]: EXCLUSIVE_OFFER_PRODUCT_CHILI,
    [COLOMBIAN_COUNTRY_CODE]: EXCLUSIVE_OFFER_PRODUCT_COLOMBIA,
    [PERUVIAN_COUNTRY_CODE]: EXCLUSIVE_OFFER_PRODUCT_PERU,
    default: EXCLUSIVE_OFFER_PRODUCT_DEFAULT,
};
