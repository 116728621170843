import { MultiUnitInput as LibMultiUnitInput, IUnitInputMulti } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

const MultiUnitInput: FC<Omit<IUnitInputMulti, 'translatedData'>> = ({
    multiInputs,
    feetRef,
    inchRef,
    placeholder,
    hint,
    showHint,
    dataLocator,
    filledFeet,
    filledInch,
    onChange,
    onKeyDown,
    mb,
}) => {
    const { t } = useTranslation();

    const translatedData = multiInputs.map((item) => t(item));

    return (
        <LibMultiUnitInput
            multiInputs={multiInputs}
            translatedData={translatedData}
            feetRef={feetRef}
            inchRef={inchRef}
            placeholder={placeholder}
            hint={hint}
            showHint={showHint}
            dataLocator={dataLocator}
            filledFeet={filledFeet}
            filledInch={filledInch}
            onChange={onChange}
            onKeyDown={onKeyDown}
            mb={mb}
        />
    );
};

export { MultiUnitInput };
