import Vundervafla from './components/Vundervafla';
import UserApi from './components/User';
import PaymentApi from './components/Payment';

function ApiInstance() {
    const user = new UserApi();
    const payment = new PaymentApi();
    const vundervafla = new Vundervafla();

    return {
        user,
        payment,
        vundervafla,
    };
}

export default ApiInstance();
