import { WeightGoal } from 'constants/obDynamicWeightLoss';

import dayjs from 'dayjs';

const DEFAULT_WEIGHT_DIFFERENCE = 0.2268;

export const getCoefficientForLoss = (count: number) => {
    switch (count) {
        case 0:
            return 0.2;

        case 1:
            return 0.06;

        case 2:
            return 0.05;

        case 3:

        case 4:
            return 0.035;

        case 5:

        case 6:
            return 0.02;

        case 7:

        case 8:

        case 9:
            return 0.015;

        case 10:

        case 11:

        case 12:

        default:
            return 0.01;
    }
};

export const exponentialGrowth = (start: number, index: number, coff: number) => {
    return start + index ** 2 / coff;
};

export const exponentialLoss = (start: number, end: number, index: number, coff: number) => {
    return (start - end) * (1 - coff) ** index + end;
};

export const getWeightLossPacePerWeek = (bmi: number) => {
    let weightLossPace = 2;

    if (bmi <= 24.9) {
        weightLossPace = 1;
    } else if (bmi >= 25 && bmi <= 29.9) {
        weightLossPace = 1.5;
    } else if (bmi >= 30 && bmi <= 34.9) {
        weightLossPace = 1.7;
    }

    return weightLossPace;
};

export const getTargetDate = (currentWeight: number, targetWeight: number, bmi: number, reductionRate = 1) => {
    const weightDifference = currentWeight - targetWeight;
    const weightChangePerWeek = weightDifference > 0 ? getWeightLossPacePerWeek(bmi) : DEFAULT_WEIGHT_DIFFERENCE;
    const targetDate = dayjs()
        .add(Math.ceil(Math.abs(weightDifference) / (weightChangePerWeek * reductionRate)), 'weeks')
        .toDate();

    return targetDate;
};

export enum WeightUnit {
    KG = 'kg',
    LB = 'lbs',
}

export const roundTo = (value: number, places = 0) => {
    if (typeof places === 'undefined') {
        return value;
    }

    return Number(Math.round(Number(value + `e+${places}`)) + `e-${places}`);
};

export const KG_TO_LBS_COEF = 2.205;

export const convertWeight = (value: string | number, fromUnit: WeightUnit, toUnit: WeightUnit, places?: number) => {
    if (fromUnit === toUnit) {
        return roundTo(Number(value), places);
    }

    if (fromUnit === 'kg') {
        return roundTo(Number(value) * KG_TO_LBS_COEF, places);
    }

    return roundTo(Number(value) / KG_TO_LBS_COEF, places);
};

export const getWeightValue = (weight: { unit: string; value: string }) => {
    if (weight.unit === WeightUnit.LB) {
        return convertWeight(weight.value, WeightUnit.KG, WeightUnit.LB);
    }

    return Number(weight.value);
};

export const getWeightGoal = (currentWeight: number, targetWeight: number) => {
    const weightDifference = currentWeight - targetWeight;

    if (weightDifference > 0) {
        return WeightGoal.LOSE;
    }
    if (weightDifference < 0) {
        return WeightGoal.GAIN;
    }

    return WeightGoal.MAINTAIN;
};
