/* eslint-disable max-lines */
export default [
    {
        path: 'OnboardingPages/ObTagsA',
        route: 'ob-tags-a',
    },
    {
        path: 'OnboardingPages/ObTagsB',
        route: 'ob-tags-b',
    },
    {
        path: 'OnboardingPages/ObWhatIsFastingA',
        route: 'ob-what-is-fasting-a',
    },
    {
        path: 'OnboardingPages/ObBehaveChoiceSc',
        route: 'ob-behave-choice-sc',
    },
    {
        path: 'OnboardingPages/ObAgeFeedback',
        route: 'ob-age-feedback',
    },
    {
        path: 'OnboardingPages/ObBehaveEatingSc',
        route: 'ob-behave-eating-sc',
    },
    {
        path: 'OnboardingPages/ObBehaveMultitaskingSc',
        route: 'ob-behave-multitasking-sc',
    },
    {
        path: 'OnboardingPages/ObBehaveRoutinesSc',
        route: 'ob-behave-routines-sc',
    },
    {
        path: 'OnboardingPages/ObBehaveOldHabitsSc',
        route: 'ob-behave-oldhabits-sc',
    },
    {
        path: 'OnboardingPages/ObBehaveImpulseSc',
        route: 'ob-behave-impulse-sc',
    },
    {
        path: 'OnboardingPages/ObPaceGoal',
        route: 'ob-pace-goal',
    },
    {
        path: 'OnboardingPages/ObGoalSet',
        route: 'ob-goal-set',
    },
    {
        path: 'OnboardingPages/ObGoalsOld',
        route: 'ob-goals-old',
    },
    {
        path: 'OnboardingPages/ObGoalsMix',
        route: 'ob-goals-mix',
    },
    {
        path: 'OnboardingPages/ObGoalsConfig',
        route: 'ob-goals-config',
    },
    {
        path: 'OnboardingPages/ObGoalsMixA',
        route: 'ob-goals-mix-a',
    },
    {
        path: 'OnboardingPages/ObGoalsMixB',
        route: 'ob-goals-mix-b',
    },
    {
        path: 'OnboardingPages/ObGoalsMixC',
        route: 'ob-goals-mix-c',
    },
    {
        path: 'OnboardingPages/ObWwEat',
        route: 'ob-ww-eat',
    },
    {
        path: 'OnboardingPages/ObPsychological',
        route: 'ob-psychological',
    },
    {
        path: 'OnboardingPages/CreatingD',
        route: 'creating-d',
    },
    {
        path: 'OnboardingPages/CreatingConfig',
        route: 'creating-config',
    },
    {
        path: 'OnboardingPages/ObCreating1',
        route: 'ob-creating-1',
    },
    {
        path: 'OnboardingPages/ObCreating2',
        route: 'ob-creating-2',
    },
    {
        path: 'OnboardingPages/ObCreating3',
        route: 'ob-creating-3',
    },
    {
        path: 'OnboardingPages/ObMenopauseSymptoms',
        route: 'ob-menopause-symptoms',
    },
    {
        path: 'OnboardingPages/ObMenopauseSymptomsFeedback',
        route: 'ob-menopause-symptoms-feedback',
    },
    {
        path: 'OnboardingPages/ObMenopauseEmotions',
        route: 'ob-menopause-emotions',
    },
    {
        path: 'OnboardingPages/ObMenopauseEmotionsFeedback',
        route: 'ob-menopause-emotions-feedback',
    },
    {
        path: 'OnboardingPages/ObEmotionTriggers',
        route: 'ob-emotion-triggers',
    },
    {
        path: 'StartPages/StartAgeMix',
        route: 'start-age-mix',
    },
    {
        path: 'StartPages/StartAgeMixConfigA',
        route: 'start-age-mix-config-a',
    },
    {
        path: 'StartPages/StartAgeMixConfig',
        route: 'start-age-mix-config',
    },
    {
        path: 'StartPages/StartAgeMixConfigB',
        route: 'start-age-mix-config-b',
    },
    {
        path: 'StartPages/StartUnisex',
        route: 'start-unisex',
    },
    {
        path: 'StartPages/Index',
        route: 'index',
    },
    {
        path: 'StartPages/StartSimpleD',
        route: 'start-simple-d',
    },
    {
        path: 'StartPages/StartBlogger',
        route: 'start-blogger',
    },
    {
        path: 'StartPages/StartBloggerA',
        route: 'start-blogger-a',
    },
    {
        path: 'StartPages/StartGoal',
        route: 'start-goal',
    },
    {
        path: 'StartPages/StartGoalA',
        route: 'start-goal-a',
    },
    {
        path: 'StartPages/StartGoalB',
        route: 'start-goal-b',
    },
    {
        path: 'StartPages/StartGoalC',
        route: 'start-goal-c',
    },
    {
        path: 'OnboardingPages/EmailConsent',
        route: 'email-consent',
    },
    {
        path: 'OnboardingPages/EmailConsentConfig',
        route: 'email-consent-config',
    },
    {
        path: 'OnboardingPages/EmailConsentConfigA',
        route: 'email-consent-config-a',
    },
    {
        path: 'PaymentLandingPages/UpsellTwo',
        route: 'upsell-2',
    },
    {
        path: 'PaymentLandingPages/UpsellSub',
        route: 'upsell-sub',
    },
    {
        path: 'PaymentLandingPages/UpsellSubB',
        route: 'upsell-sub-b',
    },
    {
        path: 'PaymentLandingPages/UpsellBundle',
        route: 'upsell-bundle',
    },
    {
        path: 'PaymentLandingPages/UpsellBundleMega',
        route: 'upsell-bundle-mega',
    },
    {
        path: 'GeneralPages/VipSupport',
        route: 'vip-support',
    },
    {
        path: 'OnboardingPages/ObHeightA',
        route: 'ob-height-a',
    },
    {
        path: 'OnboardingPages/ObCurrentWeightA',
        route: 'ob-weight-current-a',
    },
    {
        path: 'OnboardingPages/ObCurrentWeightC',
        route: 'ob-weight-current-c',
    },
    {
        path: 'OnboardingPages/ObTargetWeightA',
        route: 'ob-weight-target-a',
    },
    {
        path: 'OnboardingPages/ObLooseWeightReason',
        route: 'ob-loose-weight-reason',
    },
    {
        path: 'OnboardingPages/ObLooseWeightReasonA',
        route: 'ob-loose-weight-reason-a',
    },
    {
        path: 'OnboardingPages/ObAgeA',
        route: 'ob-age-a',
    },
    {
        path: 'OnboardingPages/ObFastingAwareness',
        route: 'ob-fasting-awareness',
    },
    {
        path: 'OnboardingPages/ObAwarenessConfig',
        route: 'ob-awareness-config',
    },
    {
        path: 'OnboardingPages/CurrentBody',
        route: 'ob-body-type-current',
    },
    {
        path: 'OnboardingPages/CurrentBodyTypeMix',
        route: 'ob-body-type-current-mix',
    },
    {
        path: 'OnboardingPages/ObBodyTypeCurrentMixA',
        route: 'ob-body-type-current-mix-a',
    },
    {
        path: 'OnboardingPages/ObBodyTypeCurrentMixB',
        route: 'ob-body-type-current-mix-b',
    },
    {
        path: 'OnboardingPages/TargetBody',
        route: 'ob-body-type-target',
    },
    {
        path: 'OnboardingPages/TargetBodyTypeMix',
        route: 'ob-body-type-target-mix',
    },
    {
        path: 'OnboardingPages/ObBodyTypeTargetMixA',
        route: 'ob-body-type-target-mix-a',
    },
    {
        path: 'OnboardingPages/TargetZones',
        route: 'ob-target-zones',
    },
    {
        path: 'OnboardingPages/TargetZonesMix',
        route: 'ob-target-zones-mix',
    },
    {
        path: 'OnboardingPages/ObTargetZonesMixConfig',
        route: 'ob-target-zones-mix-config',
    },
    {
        path: 'OnboardingPages/TargetZonesConfig',
        route: 'ob-target-zones-config',
    },
    {
        path: 'OnboardingPages/BreakfastTime',
        route: 'ob-time-breakfast',
    },
    {
        path: 'OnboardingPages/ObBadHabits',
        route: 'ob-bad-habits',
    },
    {
        path: 'OnboardingPages/LunchTime',
        route: 'ob-time-lunch',
    },
    {
        path: 'OnboardingPages/DinnerTime',
        route: 'ob-time-dinner',
    },
    {
        path: 'OnboardingPages/PreferredPlace',
        route: 'ob-pref-eat-place',
    },
    {
        path: 'OnboardingPages/FastingWeekend',
        route: 'ob-weekend',
    },
    {
        path: 'OnboardingPages/ObWorkoutMix',
        route: 'ob-workout-mix',
    },
    {
        path: 'OnboardingPages/ObWorkoutMixConfig',
        route: 'ob-workout-mix-config',
    },
    {
        path: 'OnboardingPages/JobActive',
        route: 'ob-job-active',
    },
    {
        path: 'OnboardingPages/ObExercises',
        route: 'ob-exercises',
    },
    {
        path: 'OnboardingPages/ObExercisesFeedback',
        route: 'ob-exercises-feedback',
    },
    {
        path: 'OnboardingPages/FastingSleep',
        route: 'ob-sleep',
    },
    {
        path: 'OnboardingPages/FastingWater',
        route: 'ob-water',
    },
    {
        path: 'OnboardingPages/ObWaterB',
        route: 'ob-water-b',
    },
    {
        path: 'OnboardingPages/FastingMotivation',
        route: 'ob-motivation',
    },
    {
        path: 'OnboardingPages/ObMotivationConfig',
        route: 'ob-motivation-config',
    },
    {
        path: 'OnboardingPages/HappyWeight',
        route: 'ob-happy-weight',
    },
    {
        path: 'OnboardingPages/ObHappyWeightB',
        route: 'ob-happy-weight-b',
    },
    {
        path: 'OnboardingPages/ObTipsHowTakePhoto',
        route: 'ob-tips-how-take-photo',
    },
    {
        path: 'OnboardingPages/ObTipsHowTakePhotoB',
        route: 'ob-tips-how-take-photo-b',
    },
    {
        path: 'OnboardingPages/ObTipsHowTakePhotoC',
        route: 'ob-tips-how-take-photo-c',
    },
    {
        path: 'OnboardingPages/ObGender',
        route: 'ob-gender',
    },
    {
        path: 'OnboardingPages/ObGenderB',
        route: 'ob-gender-b',
    },
    {
        path: 'OnboardingPages/ObAttempts',
        route: 'ob-attempts',
    },
    {
        path: 'OnboardingPages/ObHealthConditions',
        route: 'ob-health-conditions',
    },
    {
        path: 'OnboardingPages/ObGoal2',
        route: 'ob-goal-2',
    },
    {
        path: 'OnboardingPages/ObEmotions1',
        route: 'ob-emotions-1',
    },
    {
        path: 'OnboardingPages/ObEmotions2',
        route: 'ob-emotions-2',
    },
    {
        path: 'OnboardingPages/ObEmotions3',
        route: 'ob-emotions-3',
    },
    {
        path: 'OnboardingPages/ObEmotions4',
        route: 'ob-emotions-4',
    },
    {
        path: 'OnboardingPages/WorkloadType',
        route: 'ob-job-load',
    },
    {
        path: 'ResultPages/Result',
        route: 'result',
    },
    {
        path: 'ResultPages/ResultConfig',
        route: 'result-config',
    },
    {
        path: 'OnboardingPages/ObWeeklyEffect',
        route: 'ob-weekly-effect',
    },
    {
        path: 'OnboardingPages/ObWeeklyEffectA',
        route: 'ob-weekly-effect-a',
    },
    {
        path: 'OnboardingPages/ObWeeklyEffectB',
        route: 'ob-weekly-effect-b',
    },
    {
        path: 'OnboardingPages/ObStairs',
        route: 'ob-stairs',
    },
    {
        path: 'OnboardingPages/ObWalking',
        route: 'ob-walking',
    },
    {
        path: 'OnboardingPages/ObBackIssue',
        route: 'ob-back-issue',
    },
    {
        path: 'OnboardingPages/ObBackIssueConfig',
        route: 'ob-back-issue-config',
    },
    {
        path: 'OnboardingPages/ObMythsA',
        route: 'ob-myths-a',
    },
    {
        path: 'OnboardingPages/ObMythsB',
        route: 'ob-myths-b',
    },
    {
        path: 'OnboardingPages/ObMythsC',
        route: 'ob-myths-c',
    },
    {
        path: 'OnboardingPages/ObTargetZonesMixA',
        route: 'ob-target-zones-mix-a',
    },
    {
        path: 'OnboardingPages/ObTargetZonesMixB',
        route: 'ob-target-zones-mix-b',
    },
    {
        path: 'OnboardingPages/ObStatementFoodEmotions',
        route: 'ob-statement-food-emotions',
    },
    {
        path: 'OnboardingPages/ObStatementClearPlate',
        route: 'ob-statement-clear-plate',
    },
    {
        path: 'OnboardingPages/ObPregnant',
        route: 'ob-pregnant',
    },
    {
        path: 'OnboardingPages/ObMedications',
        route: 'ob-medications',
    },
    {
        path: 'OnboardingPages/ObUnfortunately',
        route: 'ob-unfortunately',
    },
    {
        path: 'OnboardingPages/ObFortunately',
        route: 'ob-fortunately',
    },
    {
        path: 'OnboardingPages/ObTempo',
        route: 'ob-tempo',
    },
    {
        path: 'OnboardingPages/ObOccasion',
        route: 'ob-occasion',
    },
    {
        path: 'OnboardingPages/ObOccasionResult',
        route: 'ob-occasion-result',
    },
    {
        path: 'OnboardingPages/ObOccasionResultB',
        route: 'ob-occasion-result-b',
    },
    {
        path: 'OnboardingPages/ResultWeightOnlyA',
        route: 'result-weight-only-a',
    },
    {
        path: 'OnboardingPages/ResultWeightOnlyB',
        route: 'result-weight-only-b',
    },
    {
        path: 'OnboardingPages/ObActivityLevel',
        route: 'ob-activity-level',
    },
    {
        path: 'PaymentLandingPages/PaymentE/PaymentE1Foxtrot4',
        route: 'payment-e1-foxtrot4',
    },
    {
        path: 'PaymentLandingPages/PaymentE/PaymentE9Foxtrot12',
        route: 'payment-e9-foxtrot12',
    },
    {
        path: 'PaymentLandingPages/PaymentE/PaymentE8Foxtrot12',
        route: 'payment-e8-foxtrot12',
    },
    {
        path: 'PaymentLandingPages/PaymentE/PaymentE10Foxtrot12',
        route: 'payment-e10-foxtrot12',
    },
    {
        path: 'PaymentLandingPages/PaymentE/PaymentE11Foxtrot12',
        route: 'payment-e11-foxtrot12',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG4Foxtrot16',
        route: 'payment-g4-foxtrot16',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG4Foxtrot16Config',
        route: 'payment-g4-foxtrot16-config',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG5Foxtrot17Config',
        route: 'payment-g5-foxtrot17-config',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG5Foxtrot16Config',
        route: 'payment-g5-foxtrot16-config',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG5Foxtrot19Config',
        route: 'payment-g5-foxtrot19-config',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG6Foxtrot19All',
        route: 'payment-g6-foxtrot19-all',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG7Foxtrot21Disclaimer',
        route: 'payment-g7-foxtrot21-disclaimer',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG5Foxtrot20Config',
        route: 'payment-g5-foxtrot20-config',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG7Foxtrot20Config',
        route: 'payment-g7-foxtrot20-config',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA4Foxtrot16',
        route: 'offer-a4-foxtrot16',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA4Foxtrot16Config',
        route: 'offer-a4-foxtrot16-config',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA5Foxtrot16Config',
        route: 'offer-a5-foxtrot16-config',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA5Foxtrot19Config',
        route: 'offer-a5-foxtrot19-config',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA6Foxtrot19All',
        route: 'offer-a6-foxtrot19-all',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA7Foxtrot21Disclaimer',
        route: 'offer-a7-foxtrot21-disclaimer',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA5Foxtrot20Config',
        route: 'offer-a5-foxtrot20-config',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA7Foxtrot20Config',
        route: 'offer-a7-foxtrot20-config',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA5Foxtrot17Config',
        route: 'offer-a5-foxtrot17-config',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferE1Foxtrot4',
        route: 'offer-e1-foxtrot4',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferE4Foxtrot4',
        route: 'offer-e4-foxtrot4',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferE9Foxtrot12',
        route: 'offer-e9-foxtrot12',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferE11Foxtrot12',
        route: 'offer-e11-foxtrot12',
    },
    {
        path: 'OnboardingPages/Email',
        route: 'email',
    },
    {
        path: 'OnboardingPages/EmailA',
        route: 'email-a',
    },
    {
        path: 'OnboardingPages/EmailE',
        route: 'email-e',
    },
    {
        path: 'OnboardingPages/EmailF',
        route: 'email-f',
    },
    {
        path: 'OnboardingPages/EmailAccount',
        route: 'email-account',
    },
    {
        path: 'OnboardingPages/EmailConfig',
        route: 'email-config',
    },
    {
        path: 'OnboardingPages/EmailConfigA',
        route: 'email-config-a',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofA',
        route: 'ob-social-proof-a',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofC',
        route: 'ob-social-proof-c',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofMix',
        route: 'ob-social-proof-mix',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofConfig',
        route: 'ob-social-proof-config',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofAges',
        route: 'ob-social-proof-ages',
    },
    {
        path: 'OnboardingPages/ObSocialFeedback/ObSocialFeedback',
        route: 'ob-social-feedback',
    },
    {
        path: 'OnboardingPages/FeedbackSummary',
        route: 'feedback-summary',
    },
    {
        path: 'OnboardingPages/FeedbackSummaryConfig',
        route: 'feedback-summary-config',
    },
    {
        path: 'OnboardingPages/FeedbackSummaryConfigB',
        route: 'feedback-summary-config-b',
    },
    {
        path: 'OnboardingPages/ObFoodYellowList',
        route: 'ob-food-yellow-list',
    },
    {
        path: 'OnboardingPages/ObFoodGreenList',
        route: 'ob-food-green-list',
    },
    {
        path: 'OnboardingPages/ObFoodRedList',
        route: 'ob-food-red-list',
    },
    {
        path: 'OnboardingPages/ObFoodIntro',
        route: 'ob-food-intro',
    },
    {
        path: 'OnboardingPages/ObFoodRedInfo',
        route: 'ob-food-red-info',
    },
    {
        path: 'OnboardingPages/ObFoodGreenInfo',
        route: 'ob-food-green-info',
    },
    {
        path: 'OnboardingPages/ObFoodYellowInfo',
        route: 'ob-food-yellow-info',
    },
    {
        path: 'OnboardingPages/FoodResult',
        route: 'food-result',
    },
    {
        path: 'OnboardingPages/ObRestrictiveDiet',
        route: 'ob-restrictive-diet',
    },
    {
        path: 'OnboardingPages/ObCheatMeal',
        route: 'ob-cheat-meal',
    },
    {
        path: 'OnboardingPages/ObSkinTone',
        route: 'ob-skin-tone',
    },
    {
        path: 'OnboardingPages/ObFeelingsWeight',
        route: 'ob-feelings-weight',
    },
    {
        path: 'OnboardingPages/ObBodyTypeCurrentMorph',
        route: 'ob-body-type-current-morph',
    },
    {
        path: 'OnboardingPages/ObFeeling',
        route: 'ob-feeling',
    },
    {
        path: 'OnboardingPages/ObFeelingA',
        route: 'ob-feeling-a',
    },
    {
        path: 'OnboardingPages/ObFeelings',
        route: 'ob-feelings',
    },
    {
        path: 'OnboardingPages/ObFeelingsA',
        route: 'ob-feelings-a',
    },
    {
        path: 'OnboardingPages/ObFeelingsAFeedback',
        route: 'ob-feelings-a-feedback',
    },
    {
        path: 'OnboardingPages/ObTrackersFeedback',
        route: 'ob-trackers-feedback',
    },
    {
        path: 'OnboardingPages/ObFitnessTracker',
        route: 'ob-fitness-tracker',
    },
    {
        path: 'OnboardingPages/ObScale',
        route: 'ob-scale',
    },
    {
        path: 'OnboardingPages/ObYoyoDiets',
        route: 'ob-yoyo-diets',
    },
    {
        path: 'OnboardingPages/ObQuizStart',
        route: 'ob-quiz-start',
    },
    {
        path: 'OnboardingPages/ObHealthFeedback',
        route: 'ob-health-feedback',
    },
    {
        path: 'OnboardingPages/ObTrustFeedback',
        route: 'ob-trust-feedback',
    },
    {
        path: 'OnboardingPages/ObPacePlanA',
        route: 'ob-pace-plan-a',
    },
    {
        path: 'OnboardingPages/ObFinalFeedback',
        route: 'ob-final-feedback',
    },
    {
        path: 'OnboardingPages/ObFeedback1',
        route: 'ob-feedback-1',
    },
    {
        path: 'OnboardingPages/ObFeedback2',
        route: 'ob-feedback-2',
    },
    {
        path: 'OnboardingPages/ObDiets',
        route: 'ob-diets',
    },
    {
        path: 'OnboardingPages/ObMotivation1',
        route: 'ob-motivation-1',
    },
    {
        path: 'OnboardingPages/ObMotivation2',
        route: 'ob-motivation-2',
    },
    {
        path: 'OnboardingPages/ObMotivation3',
        route: 'ob-motivation-3',
    },
    {
        path: 'OnboardingPages/ObPriorities',
        route: 'ob-priorities',
    },
    {
        path: 'OnboardingPages/ObGoal1',
        route: 'ob-goal-1',
    },
    {
        path: 'OnboardingPages/ObSnackTriggers',
        route: 'ob-snack-triggers',
    },
    {
        path: 'OnboardingPages/ObSnack',
        route: 'ob-snack',
    },
    {
        path: 'OnboardingPages/ObExperts',
        route: 'ob-experts',
    },
    {
        path: 'OnboardingPages/ObDynamicWeightLoss1',
        route: 'ob-dynamic-weight-loss-1',
    },
    {
        path: 'OnboardingPages/ObDynamicWeightLoss2',
        route: 'ob-dynamic-weight-loss-2',
    },
    {
        path: 'OnboardingPages/ObDynamicWeightLoss3',
        route: 'ob-dynamic-weight-loss-3',
    },
    {
        path: 'OnboardingPages/ObDynamicWeightLoss4',
        route: 'ob-dynamic-weight-loss-4',
    },
    {
        path: 'OnboardingPages/ObWelcomeToFe',
        route: 'ob-welcome-to-fe',
    },
    {
        path: 'OnboardingPages/ObMenopauseWeight',
        route: 'ob-menopause-weight',
    },
    {
        path: 'OnboardingPages/ObMenopauseWeightInfo',
        route: 'ob-menopause-weight-info',
    },
    {
        path: 'OnboardingPages/ObMenopauseWeightFeedback',
        route: 'ob-menopause-weight-feedback',
    },
    {
        path: 'OnboardingPages/ObTraumas',
        route: 'ob-traumas',
    },
    {
        path: 'OnboardingPages/ObSeeIntro',
        route: 'ob-see-intro',
    },
    {
        path: 'OnboardingPages/ObSee1',
        route: 'ob-see-1',
    },
    {
        path: 'OnboardingPages/ObSee2',
        route: 'ob-see-2',
    },
    {
        path: 'OnboardingPages/ObSee3',
        route: 'ob-see-3',
    },
    {
        path: 'GeneralPages/Registration',
        route: 'registration-page',
    },
    {
        path: 'GeneralPages/ThankYou',
        route: 'thank-you',
    },
    {
        path: 'OnboardingPages/ObYoga',
        route: 'ob-yoga',
    },
    {
        path: 'OnboardingPages/ObWorkouts',
        route: 'ob-workouts',
    },
    {
        path: 'OnboardingPages/ObPractices',
        route: 'ob-practices',
    },
    {
        path: 'OnboardingPages/ObImagine',
        route: 'ob-imagine',
    },
    {
        path: 'OnboardingPages/ObImagineB',
        route: 'ob-imagine-b',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-a',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-b',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-c',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-d',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-e',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-f',
    },
    {
        path: 'OnboardingPages/ObPhotoLoader',
        route: 'ob-photo-loader',
    },
    {
        path: 'OnboardingPages/ObPhotoLoaderB',
        route: 'ob-photo-loader-b',
    },
    {
        path: 'OnboardingPages/ObUploadPhotoB',
        route: 'ob-upload-photo-b',
    },
    {
        path: 'OnboardingPages/ObCycle',
        route: 'ob-cycle',
    },
    {
        path: 'OnboardingPages/ObSymptoms',
        route: 'ob-symptoms',
    },
    {
        path: 'OnboardingPages/ObHormonesResult',
        route: 'ob-hormones-result',
    },
    {
        path: 'OnboardingPages/ObEmotions',
        route: 'ob-emotions',
    },
    {
        path: 'OnboardingPages/ObTransition',
        route: 'ob-transition',
    },
] as { path: string; route: string }[];
