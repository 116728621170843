import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { PropsWithChildren, useEffect } from 'react';
import classNames from 'classnames/bind';

import { useNetworkStatus } from 'hooks';

import Timer from 'components/Timer';

import modalLightStyles from './ModalLight.module.css';
import modalStyles from './Modal.module.css';

import { IModalProps } from './types';

import ModalClose from '../ModalClose/ModalClose';

function Modal({
    children,
    isOpen = false,
    onClose = () => {},
    withClose = true,
    isTransparency,
    isLeftClose,
    isExclusiveOffer,
    paddingTop32,
    paddingTop40,
    isSmallClose,
    isFlex,
    isFixed,
    isLightTheme,
    withOpacity = false,
    isShowModal = false,
    withTimer,
    timerTime,
    timerEndHandler,
    isActiveTimer,
    modalCloseDataLocator,
    isFullScreen,
    centeredModal,
    networkModal = false,
}: PropsWithChildren<IModalProps>) {
    useEffect(() => {
        ReactModal.setAppElement('#app');
    }, []);

    const isOnline = useNetworkStatus();

    const { t } = useTranslation();

    const closeModal = () => {
        if (onClose) onClose();
    };

    const stylesForModal = isLightTheme ? modalLightStyles : modalStyles;

    const cx = classNames.bind(stylesForModal);
    const contentStyles = cx('ReactModal__Content', 'ReactModal__Content__desktop', {
        ReactModal__Content__withTransparency: isTransparency,
        ReactModal__Content__fixed: isFixed,
        ReactModal__Content__exclusiveOffer: isExclusiveOffer,
        ReactModal__Content__paddingTop32: paddingTop32,
        ReactModal__Content__paddingTop40: paddingTop40,
        ReactModal__Content__flex: isFlex,
        ReactModal__Content__withTimerOn: withTimer && isActiveTimer,
        ReactModal__Content__withTimerOff: withTimer && !isActiveTimer,
        ReactModal__Content__fullScreen: isFullScreen,
        centeredModal,
    });

    const overlayStyles = cx({
        ReactModal__Overlay: isOnline,
        ReactModal__Overlay__isClose: withOpacity && !isShowModal,
        ReactModal__Overlay__isOpen: withOpacity && isShowModal,
        ReactModal__Overlay__withTransparency: isTransparency,
        ReactModal__Overlay__checkoutWitTimer: withTimer,
        networkModalOverlay: networkModal,
    });

    const bodyStyles = cx({
        ReactModal__Body__isClose: withOpacity && !isShowModal,
        ReactModal__Body__isOpen: withOpacity && isShowModal,
    });

    return (
        <ReactModal
            shouldCloseOnOverlayClick={!isFixed}
            shouldCloseOnEsc={false}
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            className={contentStyles}
            overlayClassName={overlayStyles}
            bodyOpenClassName={bodyStyles}
        >
            {withTimer && isActiveTimer && timerTime && timerEndHandler && (
                <Timer
                    checkoutTimer
                    withSVG
                    timerText={t('paymentFlow.paymentBa.planReady.timer')}
                    initTimer={timerTime}
                    timerEnd={timerEndHandler}
                />
            )}
            {withClose && (
                <ModalClose
                    onClick={closeModal}
                    isLeftClose={isLeftClose}
                    isSmallClose={isSmallClose}
                    withTimer
                    isActiveTimer={isActiveTimer}
                    dataLocator={modalCloseDataLocator}
                />
            )}
            {children}
        </ReactModal>
    );
}

export default Modal;
