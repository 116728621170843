import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { selectPayment } from 'redux/Payment/selectors';

import { DAYS_PER_WEEK } from 'constants/payments';

import { IPaymentFlow } from 'hooks/types';

import { getCardsConfigFoxtrot16, getProductPaymentDataWithSubsHelper } from 'helpers/utilsUpdated';

import { CurrentProduct } from 'types/payments/payments';

import { Dayjs } from 'dayjs';

const HARDCODED_TRIAL_DURATION = { count: 7, context: 'day' };

export const useCardsAndCountriesDisclaimersConfig = ({
    currentProductForPayment,
    timerTime,
    products,
    pageInfo,
    defaultTrialPrice,
    withFullPrice,
}: {
    currentProductForPayment: CurrentProduct;
    timerTime: { time_to: Dayjs; seconds: number };
    products: CurrentProduct[];
    pageInfo: IPaymentFlow;
    defaultTrialPrice: number;
    withFullPrice?: boolean;
    isLatamSpecial?: boolean;
}) => {
    const { currency } = useSelector(selectPayment);

    const paymentTypeData = getProductPaymentDataWithSubsHelper(currentProductForPayment);

    const { t } = useTranslation();

    const fullPriceTrial = pageInfo?.config?.trialFullPrice || defaultTrialPrice;

    const getDisclaimerWithWeekTrialBeforeTimer = (isTrialOneWeek?: boolean) =>
        isTrialOneWeek
            ? t('paymentFlow.paymentG5Foxtrot20.disclaimerTrial.US.timerOn', {
                  fullPrice: fullPriceTrial,
                  currency: currency.sign,
                  countTrial: HARDCODED_TRIAL_DURATION.count,
                  countFull: paymentTypeData.paymentLabel?.count,
                  contextTrial: HARDCODED_TRIAL_DURATION.context,
                  contextFull: paymentTypeData.paymentLabel?.context,
              })
            : t('paymentFlow.paymentG5Foxtrot20.disclaimerRegular.US.timerOn', {
                  fullPrice: paymentTypeData.fullPrice,
                  currency: currency.sign,
                  countFull: paymentTypeData.paymentLabel?.count,
                  contextFull: paymentTypeData.paymentLabel?.context,
              });

    const getDisclaimerAfterTimer = (isTrialOneWeek?: boolean) => {
        if (isTrialOneWeek) {
            return t('paymentFlow.paymentScreen.disclaimerAll.timerOff', {
                currency: currency.sign,
                trialPrice: paymentTypeData.fullPrice,
                contextTrial: HARDCODED_TRIAL_DURATION.context,
                countTrial: HARDCODED_TRIAL_DURATION.count,
                fullPrice: fullPriceTrial,
                countFull: paymentTypeData.paymentLabel?.count,
                contextFull: paymentTypeData.paymentLabel?.context,
            });
        }

        return t('paymentFlow.paymentG5Foxtrot20.disclaimerAll.timerOff', {
            fullPrice: paymentTypeData.fullPrice,
            currency: currency.sign,
            countFull: paymentTypeData.paymentLabel?.count,
            contextFull: paymentTypeData.paymentLabel?.context,
        });
    };

    const [disclaimer, setDisclaimer] = useState('');
    const [isTrialOneWeek, setIsTrialOneWeek] = useState<boolean | undefined>();

    useEffect(() => {
        setIsTrialOneWeek(currentProductForPayment.trial === DAYS_PER_WEEK);
    }, [currentProductForPayment]);

    useEffect(() => {
        if (timerTime?.seconds > 0) {
            setDisclaimer(getDisclaimerWithWeekTrialBeforeTimer(isTrialOneWeek));
        } else {
            setDisclaimer(getDisclaimerAfterTimer(isTrialOneWeek));
        }
    }, [currentProductForPayment, isTrialOneWeek]);

    const cardsConfigure = getCardsConfigFoxtrot16(products, t, withFullPrice);

    return {
        isTrialOneWeek,
        disclaimerText: disclaimer,
        cardsConfigure,
    };
};
