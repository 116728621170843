import type { TFunction } from 'react-i18next';

const HARDCODED_TRIAL_DURATION = { count: 7, context: 'day' };
const OLD_DISCLAIMER = 'old';
const BOLD_DISCLAIMER = 'bold';

const getOldDisclaimersBeforeTimer = (data: any, t: TFunction) => {
    const { trialPrice, isTrialOneWeek, finalCurrency, paymentTypeData } = data;

    return isTrialOneWeek
        ? t('paymentFlow.paymentG4Foxtrot16.disclaimerTrial.timerOn', {
              fullPrice: trialPrice,
              currency: finalCurrency.sign,
              count1: HARDCODED_TRIAL_DURATION.count,
              count2: paymentTypeData.paymentLabel?.count,
              context1: HARDCODED_TRIAL_DURATION.context,
              context2: paymentTypeData.paymentLabel?.context,
          })
        : t('paymentFlow.paymentG7Foxtrot21Disclaimer.disclaimerRegular.old.timerOn', {
              fullPrice: paymentTypeData.fullPrice,
              currency: finalCurrency.sign,
          });
};

const getNewDisclaimersBeforeTimer = (data: any, t: TFunction) => {
    const { trialPrice, isTrialOneWeek, finalCurrency, paymentTypeData } = data;

    return isTrialOneWeek
        ? t('paymentFlow.paymentG7Foxtrot21Disclaimer.disclaimerTrial.new.timerOn', {
              fullPrice: trialPrice,
              currency: finalCurrency.sign,
              countTrial: HARDCODED_TRIAL_DURATION.count,
              countFull: paymentTypeData.paymentLabel?.count,
              contextTrial: HARDCODED_TRIAL_DURATION.context,
              contextFull: paymentTypeData.paymentLabel?.context,
          })
        : t('paymentFlow.paymentG7Foxtrot21Disclaimer.disclaimerRegular.new.timerOn', {
              fullPrice: paymentTypeData.fullPrice,
              currency: finalCurrency.sign,
              countFull: paymentTypeData.paymentLabel?.count,
              contextFull: paymentTypeData.paymentLabel?.context,
          });
};

const getNewBoldDisclaimersBeforeTimer = (data: any, t: TFunction) => {
    const { trialPrice, isTrialOneWeek, finalCurrency, paymentTypeData } = data;

    return isTrialOneWeek
        ? t('paymentFlow.paymentG7Foxtrot21Disclaimer.disclaimerTrial.bold.timerOn', {
              fullPrice: trialPrice,
              currency: finalCurrency.sign,
              countTrial: HARDCODED_TRIAL_DURATION.count,
              countFull: paymentTypeData.paymentLabel?.count,
              contextTrial: HARDCODED_TRIAL_DURATION.context,
              contextFull: paymentTypeData.paymentLabel?.context,
          })
        : t('paymentFlow.paymentG7Foxtrot21Disclaimer.disclaimerRegular.bold.timerOn', {
              fullPrice: paymentTypeData.fullPrice,
              currency: finalCurrency.sign,
              countFull: paymentTypeData.paymentLabel?.count,
              contextFull: paymentTypeData.paymentLabel?.context,
          });
};

const getOldDisclaimersAfterTimer = (data: any, t: TFunction) => {
    const { trialPrice, isTrialOneWeek, finalCurrency, paymentTypeData } = data;

    return isTrialOneWeek
        ? t('paymentFlow.paymentG4Foxtrot16.disclaimerTrial.timerOff', {
              fullPrice: trialPrice,
              currency: finalCurrency.sign,
              count1: HARDCODED_TRIAL_DURATION.count,
              count2: paymentTypeData.paymentLabel?.count,
              context1: HARDCODED_TRIAL_DURATION.context,
              context2: paymentTypeData.paymentLabel?.context,
          })
        : t('paymentFlow.paymentG7Foxtrot21Disclaimer.disclaimerRegular.old.timerOff', {
              fullPrice: paymentTypeData.fullPrice,
              currency: finalCurrency.sign,
          });
};

const getNewDisclaimersAfterTimer = (data: any, t: TFunction) => {
    const { trialPrice, isTrialOneWeek, finalCurrency, paymentTypeData } = data;

    return isTrialOneWeek
        ? t('paymentFlow.paymentScreen.disclaimerAll.timerOff', {
              currency: finalCurrency.sign,
              trialPrice: paymentTypeData.fullPrice,
              contextTrial: HARDCODED_TRIAL_DURATION.context,
              countTrial: HARDCODED_TRIAL_DURATION.count,
              fullPrice: trialPrice,
              countFull: paymentTypeData.paymentLabel?.count,
              contextFull: paymentTypeData.paymentLabel?.context,
          })
        : t('paymentFlow.paymentG7Foxtrot21Disclaimer.disclaimerRegular.new.timerOff', {
              fullPrice: paymentTypeData.fullPrice,
              currency: finalCurrency.sign,
              countFull: paymentTypeData.paymentLabel?.count,
              contextFull: paymentTypeData.paymentLabel?.context,
          });
};

const getNewBoldDisclaimersAfterTimer = (data: any, t: TFunction) => {
    const { trialPrice, isTrialOneWeek, finalCurrency, paymentTypeData } = data;

    return isTrialOneWeek
        ? t('paymentFlow.paymentG7Foxtrot21Disclaimer.disclaimerTrial.bold.timerOff', {
              currency: finalCurrency.sign,
              trialPrice: paymentTypeData.fullPrice,
              contextTrial: HARDCODED_TRIAL_DURATION.context,
              countTrial: HARDCODED_TRIAL_DURATION.count,
              fullPrice: trialPrice,
              countFull: paymentTypeData.paymentLabel?.count,
              contextFull: paymentTypeData.paymentLabel?.context,
          })
        : t('paymentFlow.paymentG7Foxtrot21Disclaimer.disclaimerRegular.bold.timerOff', {
              fullPrice: paymentTypeData.fullPrice,
              currency: finalCurrency.sign,
              countFull: paymentTypeData.paymentLabel?.count,
              contextFull: paymentTypeData.paymentLabel?.context,
          });
};

const getOldDisclaimerCheckoutBeforeTimer = (data: any, t: TFunction) => {
    const { trialPrice, isTrialOneWeek, finalCurrency, paymentTypeData } = data;

    return isTrialOneWeek
        ? t('paymentFlow.checkoutFoxtrot21.disclaimerTrial.timerOn', {
              fullPrice: trialPrice,
              trialPrice: paymentTypeData.trialPrice,
              currency: finalCurrency.sign,
              count1: paymentTypeData.paymentLabel?.count,
              count2: HARDCODED_TRIAL_DURATION.count,
              context1: paymentTypeData.paymentLabel?.context,
              context2: HARDCODED_TRIAL_DURATION.context,
          })
        : t('paymentFlow.checkoutFoxtrot21.disclaimer.timerOn', {
              fullPrice: paymentTypeData.fullPrice,
              trialPrice: paymentTypeData.trialPrice,
              currency: finalCurrency.sign,
              context: paymentTypeData.paymentLabel?.context,
              count: +paymentTypeData.paymentLabel?.count,
          });
};

const getOldDisclaimerCheckoutAfterTimer = (data: any, t: TFunction) => {
    const { trialPrice, isTrialOneWeek, finalCurrency, paymentTypeData } = data;

    return isTrialOneWeek
        ? t('paymentFlow.checkoutFoxtrot21.disclaimerTrial.timerOff', {
              fullPrice: trialPrice,
              trialPrice: paymentTypeData.trialPrice,
              currency: finalCurrency.sign,
              count1: paymentTypeData.paymentLabel?.count,
              count2: HARDCODED_TRIAL_DURATION.count,
              context1: paymentTypeData.paymentLabel?.context,
              context2: HARDCODED_TRIAL_DURATION.context,
          })
        : t('paymentFlow.checkoutFoxtrot21.disclaimer.timerOff', {
              fullPrice: paymentTypeData.fullPrice,
              currency: finalCurrency.sign,
              context: paymentTypeData.paymentLabel?.context,
              count: +paymentTypeData.paymentLabel?.count,
          });
};

export const getDisclaimerBeforeTimer = (disclaimer: string, data: any, t: TFunction) => {
    switch (disclaimer) {
        case OLD_DISCLAIMER:
            return getOldDisclaimersBeforeTimer(data, t);

        case BOLD_DISCLAIMER:
            return getNewBoldDisclaimersBeforeTimer(data, t);

        default:
            return getNewDisclaimersBeforeTimer(data, t);
    }
};

export const getDisclaimerAfterTimer = (disclaimer: string, data: any, t: TFunction) => {
    switch (disclaimer) {
        case OLD_DISCLAIMER:
            return getOldDisclaimersAfterTimer(data, t);

        case BOLD_DISCLAIMER:
            return getNewBoldDisclaimersAfterTimer(data, t);

        default:
            return getNewDisclaimersAfterTimer(data, t);
    }
};

export const getDisclaimerCheckoutBeforeTimer = (disclaimer: string, data: any, t: TFunction) => {
    if (disclaimer === OLD_DISCLAIMER) {
        return getOldDisclaimerCheckoutBeforeTimer(data, t);
    }

    return '';
};

export const getDisclaimerCheckoutAfterTimer = (disclaimer: string, data: any, t: TFunction) => {
    if (disclaimer === OLD_DISCLAIMER) {
        return getOldDisclaimerCheckoutAfterTimer(data, t);
    }

    return '';
};
