import { Trans } from 'react-i18next';
import CompoundTimer from 'react-compound-timerv2';
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { Icon, Text } from 'libComponents';

import styles from './Timer.module.css';

import { ITimerProps } from './types';

const cx = classNames.bind(styles);

function Timer({ initTimer, timerEnd, rounded, timerText, noMin, echoTimer, withSVG, checkoutTimer }: ITimerProps) {
    const [isTimerEnds, setIsTimerEnds] = useState(false);

    const timerClass = cx('root', {
        rounded: rounded,
        timerEnded: isTimerEnds,
        echoTitle: echoTimer,
        checkoutContainer: checkoutTimer,
    });

    const numbersClass = cx('timerBox', {
        smallTimer: checkoutTimer,
    });

    useEffect(() => {
        if (initTimer <= 0) setIsTimerEnds(true);
    }, [initTimer]);

    const handleTimerStops = () => {
        timerEnd && timerEnd();

        setIsTimerEnds(true);
    };

    return (
        <div className={timerClass}>
            {isTimerEnds ? (
                <Trans>Special offer not available</Trans>
            ) : (
                <>
                    {withSVG && (
                        <div className={styles.svgWrap}>
                            <Icon name="clock" size={checkoutTimer ? 16 : 24} libColor="decorative-c" />
                        </div>
                    )}
                    <Text bold color="decorative-c">
                        <Trans>{timerText}</Trans>&nbsp;
                    </Text>
                    <div data-locator="timerValue" className={numbersClass}>
                        <CompoundTimer
                            initialTime={initTimer}
                            startImmediately={true}
                            direction="backward"
                            checkpoints={[
                                {
                                    time: 0,
                                    callback: handleTimerStops,
                                },
                            ]}
                        >
                            <CompoundTimer.Minutes
                                formatValue={(value: number) => `${value === 10 ? value : '0' + value}`}
                            />
                            :
                            <CompoundTimer.Seconds
                                formatValue={(value: number) => `${value < 10 ? '0' + value : value}`}
                            />
                        </CompoundTimer>
                    </div>
                    {!noMin && (
                        <Text bold color="decorative-c">
                            &nbsp;
                            <Trans i18nKey="basics.min" />
                        </Text>
                    )}
                </>
            )}
        </div>
    );
}
export default Timer;
