import { Redirect } from 'react-router-dom';

import { PreloadTestania, generateRoute, pathWithRegularExpression } from 'router/helpers';

import routesMap from '../routesMap';

const routes = [
    {
        path: pathWithRegularExpression(''),
        key: 'preload-testania',
        render: (props) => <PreloadTestania {...props} />,
        exact: true,
    },
];

for (const item of routesMap) {
    const { route, path } = item;
    const payload = generateRoute(route, path);

    routes.push(payload);
}

routes.push({
    path: '*',
    key: 'redirect-public',
    render: () => <Redirect to="/en" />,
});

export default routes;
