import { useSelector } from 'react-redux';

import { selectCountry } from 'redux/User/selectors';
import { selectPayment } from 'redux/Payment/selectors';

import { LATAM_SPECIAL_MEX_CURRENCY } from 'constants/payments';
import { MEXICAN_COUNTRY_CODE } from 'constants/countriesList';

import { useAnalytics } from 'hooks';

import { getProductPaymentData } from 'helpers/utilsUpdated';

import { CurrentProduct } from 'types/payments/payments';

interface IUsePaymentIntroductoryContainerArgs {
    toNextPage?: () => void;
    setPaymentPrice: (currentProduct: CurrentProduct) => void;
    url: string;
    customEvent: string;
    isLatamSpecial?: boolean;
}

interface IPaymentTypeData {
    paymentType: string;
    currentPaymentType: string;
    paymentLabel: string;
    fullPrice: number;
    trialPrice: number;
    trialLabel: string;
}

interface IUsePaymentIntroductoryContainerReturn {
    currencySign: string;
    paymentTypeData: IPaymentTypeData | null;
    handleCurrentProduct: (data: { withAnalytic?: boolean } & CurrentProduct) => void;
    handlePlanClick: (eventValue?: string) => void;
    handleAnalyticData: (eventValue: string, customEvent?: string) => void;
}

export const usePaymentIntroductoryContainer = ({
    toNextPage,
    setPaymentPrice,
    url,
    customEvent,
    isLatamSpecial = false,
}: IUsePaymentIntroductoryContainerArgs): IUsePaymentIntroductoryContainerReturn => {
    const { customData, sendAnalyticsWithEventLabel } = useAnalytics(true);

    const { currentProduct, currency } = useSelector(selectPayment);
    const country = useSelector(selectCountry);

    const finalCurrency = isLatamSpecial && country === MEXICAN_COUNTRY_CODE ? LATAM_SPECIAL_MEX_CURRENCY : currency;

    const paymentTypeData =
        (currentProduct as CurrentProduct) && getProductPaymentData(currentProduct as CurrentProduct);

    const handleCurrentProduct = (data: { withAnalytic?: boolean } & CurrentProduct) => {
        const { withAnalytic, ...product } = data;

        const productData = getProductPaymentData(product);

        setPaymentPrice(product);

        withAnalytic && handleAnalyticData(productData?.currentPaymentType, customEvent);
    };

    const handleAnalyticData = (eventValue: string, customEvent?: string) => {
        if (customEvent) {
            customData(customEvent, { event_label: eventValue });
        } else {
            sendAnalyticsWithEventLabel({
                url: url,
                eventValue,
            });
        }
    };

    const handlePlanClick = (eventValue?: string) => {
        eventValue && handleAnalyticData(eventValue);

        toNextPage?.();
    };

    return {
        currencySign: finalCurrency.sign,
        paymentTypeData,
        handleCurrentProduct,
        handlePlanClick,
        handleAnalyticData,
    };
};
