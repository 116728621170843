import { Colors } from 'wikr-core-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { selectCountry } from 'redux/User/selectors';
import { selectPayment } from 'redux/Payment/selectors';

import { DAYS_PER_WEEK, LATAM_SPECIAL_MEX_CURRENCY } from 'constants/payments';
import { MEXICAN_COUNTRY_CODE } from 'constants/countriesList';

import { IPaymentFlow } from 'hooks/types';

import { getCardsConfigFoxtrot16, getProductPaymentDataWithSubsHelper } from 'helpers/utilsUpdated';
import { getStartPriceFromProductWithoutTrial } from 'helpers/payments';
import {
    getDisclaimerBeforeTimer,
    getDisclaimerAfterTimer,
    getDisclaimerCheckoutBeforeTimer,
    getDisclaimerCheckoutAfterTimer,
} from './helpers';

import { CurrentProduct } from 'types/payments/payments';

import { Dayjs } from 'dayjs';

export const useCardsAndConfigurableDisclaimerConfig = ({
    currentProductForPayment,
    timerTime,
    products,
    pageInfo,
    defaultTrialPrice,
    withFullPrice,
    isLatamSpecial = false,
}: {
    currentProductForPayment: CurrentProduct;
    timerTime: { time_to: Dayjs; seconds: number };
    products: CurrentProduct[];
    pageInfo: IPaymentFlow;
    defaultTrialPrice: number;
    withFullPrice?: boolean;
    isLatamSpecial?: boolean;
}) => {
    const { currency } = useSelector(selectPayment);
    const country = useSelector(selectCountry);

    const finalCurrency = isLatamSpecial && country === MEXICAN_COUNTRY_CODE ? LATAM_SPECIAL_MEX_CURRENCY : currency;

    const paymentTypeData = getProductPaymentDataWithSubsHelper(currentProductForPayment);

    const { t } = useTranslation();

    const fullPriceTrial = pageInfo?.config?.trialFullPrice || defaultTrialPrice;
    const disclaimer = pageInfo?.config?.disclaimer || 'new';

    const [disclaimerText, setDisclaimerText] = useState('');
    const [disclaimerCheckoutText, setDisclaimerCheckoutText] = useState('');
    const [isTrialOneWeek, setIsTrialOneWeek] = useState<boolean | undefined>();

    const disclaimerCheckoutColor: Colors = isTrialOneWeek ? 'text-main' : 'text-secondary';

    const data = {
        trialPrice: isLatamSpecial
            ? getStartPriceFromProductWithoutTrial({
                  products,
                  trialProductPeriod: currentProductForPayment.period,
                  defaultTrialPrice,
              })
            : fullPriceTrial,
        isTrialOneWeek,
        finalCurrency,
        paymentTypeData,
    };

    useEffect(() => {
        setIsTrialOneWeek(currentProductForPayment.trial === DAYS_PER_WEEK);
    }, [currentProductForPayment, timerTime]);

    useEffect(() => {
        if (timerTime?.seconds > 0) {
            setDisclaimerText(getDisclaimerBeforeTimer(disclaimer, data, t));
            setDisclaimerCheckoutText(getDisclaimerCheckoutBeforeTimer(disclaimer, data, t));
        } else {
            setDisclaimerText(getDisclaimerAfterTimer(disclaimer, data, t));
            setDisclaimerCheckoutText(getDisclaimerCheckoutAfterTimer(disclaimer, data, t));
        }
    }, [timerTime, currentProductForPayment, isTrialOneWeek]);

    const cardsConfigure = getCardsConfigFoxtrot16(products, t, withFullPrice);

    return {
        isTrialOneWeek,
        disclaimerText,
        cardsConfigure,
        disclaimerCheckoutText,
        disclaimerCheckoutColor,
    };
};
