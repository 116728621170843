export enum MAP_TIME_NAME {
    '1d' = '1d',
    '1w' = '1w',
    '1m' = '1m',
    '1y' = '1y',
}

const MAP_TIME: Record<MAP_TIME_NAME, number> = {
    [MAP_TIME_NAME['1d']]: 24 * 60 * 60 * 1000,
    [MAP_TIME_NAME['1w']]: 7 * 24 * 60 * 60 * 1000,
    [MAP_TIME_NAME['1m']]: 30 * 24 * 60 * 60 * 1000,
    [MAP_TIME_NAME['1y']]: 365 * 24 * 60 * 60 * 1000,
};

const getCookie = (cName: string) => {
    const name = cName + '=';
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split('; ');
    let res;

    cArr.forEach((val) => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    });

    return String(res);
};

const setCookie = (name: string, value?: string | number, time = MAP_TIME_NAME['1d']) => {
    let expires = '';

    const date = new Date();

    date.setTime(date.getTime() + MAP_TIME[time]);
    expires = '; expires=' + date.toUTCString();

    document.cookie = name + '=' + value + expires + '; path=/';
};

export { getCookie, setCookie };
