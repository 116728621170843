import { Main } from 'wikr-core-analytics';
import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { getProjectEmail } from 'helpers/utilsUpdated';

import { Box, Text } from 'libComponents';
import OutlinedButton from 'components/OutlinedButton';

import styles from './ErrorPopUp.module.css';

function ErrorPopUp() {
    const { t } = useTranslation();

    const reloadClick = () => {
        Main.customData('onboarding_page_error__click', { event_label: 'reload' });
        window.location.reload();
    };

    const toSupportClick = () => {
        Main.customData('onboarding_page_error__click', { event_label: 'contact_support' });
    };

    useEffect(() => {
        Main.customData('onboarding_page_error__load', { event_label: window.location.pathname });
    }, []);

    return (
        <Box className={styles.popUpWrap}>
            <Box className={styles.errorPopUpBlock}>
                <Text type="h1" center text="😵‍💫" mb={20} dataLocator="wentWrongPageEmoji" />
                <Text type="h5" center text={t('errorPage.title')} mb={12} dataLocator="wentWrongPageTitle" />
                <Text
                    color="text-secondary"
                    text={t('errorPage.subtitle')}
                    center
                    mb={32}
                    dataLocator="wentWrongPageSubTitle"
                />
                <div className={styles.buttonBox}>
                    <OutlinedButton
                        text={t('basics.tryAgain')}
                        onClick={reloadClick}
                        mb={24}
                        dataLocator="wentWrongPageTryAgainButton"
                        size="medium"
                    />
                </div>
                <Text dataLocator="wentWrongPageContactSupportButton" type="overline" center>
                    <a onClick={toSupportClick} href={'mailto:' + getProjectEmail()}>
                        <Trans i18nKey="basics.contactSupport" />
                    </a>
                </Text>
            </Box>
        </Box>
    );
}

export default ErrorPopUp;
