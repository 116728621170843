import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import { selectCountry } from 'redux/User/selectors';
import { selectPayment } from 'redux/Payment/selectors';
import { setCurrentProduct } from 'redux/Payment/actions';

import { IUsePaymentLogic } from 'hooks/usePaymentLogic/usePaymentLogic';
import { useSetCurrentProductAfterTimer } from 'hooks';

import { getDynamicallyDiscount } from 'helpers/utils';
import getTimerTime from 'helpers/timer';
import { getExclusiveOfferProductByCountry } from 'helpers/getExclusiveOfferProductByCountry';

import { CurrentProduct } from 'types/payments/payments';

export const usePaymentOfferLogic = ({
    timerKey = 'PaymentScreenDefaultTimer',
    productsListWithDiscount,
    productsListWithoutDiscount,
    pageInfo,
    timerLengthInMin,
}: IUsePaymentLogic) => {
    const dispatch = useDispatch();

    const country = useSelector(selectCountry);

    const EXCLUSIVE_OFFER_PRODUCT_BY_COUNTRY = getExclusiveOfferProductByCountry(country);

    const timerTime = getTimerTime({ key: timerKey }, timerLengthInMin);

    const discount = useRef(0);

    const { currentProduct, exclusiveOfferStatus: isExclusiveOfferModalOpen } = useSelector(selectPayment);

    const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
    const [isOpenExclusiveOffer, setIsOpenExclusiveOffer] = useState(false);
    const [isExclusiveOffer, setIsExclusiveOffer] = useState(false);
    const [isTimerEnd, setIsTimerEnd] = useState(timerTime?.seconds <= 0);
    const [currentProductForPayment, setCurrentProductForPayment] = useState(currentProduct || pageInfo.products[0]);

    const products = !isTimerEnd ? productsListWithDiscount : productsListWithoutDiscount;

    useSetCurrentProductAfterTimer({ isTimerEnd, products, setCurrentProductForPayment });

    useEffect(() => {
        if (isExclusiveOfferModalOpen) {
            setPaymentModalOpen(false);
        }
    }, [isExclusiveOfferModalOpen]);

    const getDiscount = (currentProduct: CurrentProduct) => {
        const selectedPeriod = currentProduct?.period;
        const selectedTrial = currentProduct?.trial;
        const currentPrice = currentProduct?.price;

        return getDynamicallyDiscount({
            products: pageInfo?.products,
            selectedPeriod,
            selectedTrial,
            currentPrice,
        });
    };

    // for preselected product setting(only with new cards)
    useEffect(() => {
        products.forEach((item) => item.is_preselected && setPaymentPrice(item));
    }, [isTimerEnd]);

    const setPaymentPrice = (currentProduct: CurrentProduct) => {
        if (!isPaymentModalOpen) {
            discount.current = getDiscount(currentProduct);

            dispatch(setCurrentProduct(currentProduct));
            setCurrentProductForPayment(currentProduct);
        }
    };

    const paymentModalHandler = () => {
        setPaymentModalOpen(false);

        if (isExclusiveOffer) {
            setIsOpenExclusiveOffer(true);
        }
    };

    const openPaymentModal = () => {
        setCurrentProductForPayment(currentProduct as CurrentProduct);
        setPaymentModalOpen(true);
    };

    const onTimerEnd = () => {
        setIsTimerEnd(true);
        discount.current = 0;
    };

    const exclusiveOfferClick = () => {
        setIsExclusiveOffer(true);

        dispatch(setCurrentProduct(EXCLUSIVE_OFFER_PRODUCT_BY_COUNTRY));
        setCurrentProductForPayment(EXCLUSIVE_OFFER_PRODUCT_BY_COUNTRY);

        setPaymentModalOpen(true);
    };

    return {
        isExclusiveOfferModalOpen: isExclusiveOfferModalOpen || isOpenExclusiveOffer,
        exclusiveOfferClick,
        exclusiveOfferProduct: EXCLUSIVE_OFFER_PRODUCT_BY_COUNTRY,
        isPaymentModalOpen,
        paymentModalHandler,
        currentProductForPayment,
        isTimerEnd,
        timerTime,
        openPaymentModal,
        products,
        discount,
        setPaymentPrice,
        onTimerEnd,
    };
};
