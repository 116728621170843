/* eslint-disable max-lines */
/* eslint-disable no-console */
import { AnalyticUtils, Main } from 'wikr-core-analytics';
import { SENTRY_PAYMENT, ERROR_LEVELS } from 'sentry-utils';
import { takeLatest, put, select, call } from 'redux-saga/effects';

import api from 'api';

import { selectUserId } from 'redux/User/selectors';
import { SET_IS_PAID } from 'redux/User/actionTypes';
import { selectTestaniaName, selectFlowLink } from 'redux/Testania/selectors';
import { Currency } from 'redux/sagas/user/interfaces';
import { RootState } from 'redux/rootReducer';
import { selectCurrentProduct, selectPaymentMethod, selectCurrency } from 'redux/Payment/selectors';
import * as actionTypes from 'redux/Payment/actionTypes';
import {
    setOrderId,
    setPaymentType,
    setSubscriptionId,
    setValidatePaymentData,
    setValidatePaymentFail,
    validatePayment as validatePaymentAction,
} from 'redux/Payment/actions';

import { CLP, PAYMENT_METHOD_NAME, PAYMENT_TYPES_NAME } from 'constants/payments';
import { DEFAULT_ACTIVE_ANALYTICS } from 'constants/analytics';

import sentry from 'services/Sentry/SentryInstance';
import { handleKwaiEvent } from 'services/Kwai/handleKwaiEvent';

import {
    cleanObject,
    deleteSlash,
    fromPennyToInt,
    generateQueryParams,
    replaceDataWithLocalhost,
    isLocalEnv,
} from 'helpers/utilsUpdated';
import { calculateLTV, getAnalyticsSystems, getContentId } from 'helpers/payments';

import { ValidateResponse, ValidatePayload } from 'types/payments/validate';
import { CurrentProduct, PaymentMethod } from 'types/payments/payments';

const getUserId = (state: RootState) => selectUserId(state);
const getCurrentProduct = (state: RootState) => selectCurrentProduct(state);
const getCurrency = (state: RootState) => selectCurrency(state);
const getPaymentMethod = (state: RootState) => selectPaymentMethod(state);
const testaniaName = (state: RootState) => selectTestaniaName(state);
const getFlowLink = (state: RootState) => selectFlowLink(state);

function* validate({ payload }: ReturnType<typeof validatePaymentAction>) {
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

    const {
        data: { order },
        paymentMethod,
    } = payload;

    const currentProduct: CurrentProduct = yield select(getCurrentProduct);
    const flow_link: string = yield select(getFlowLink);
    const currency: Currency = yield select(getCurrency);
    const userId: number = yield select(getUserId);

    yield put(setPaymentType(currentProduct?.payment_type));

    const isTrial = !!currentProduct?.trial;
    const amountValue: number = order.currency === CLP.sign ? order?.amount : fromPennyToInt(order?.amount);
    const subscriptionPrice = fromPennyToInt(currentProduct?.start_price);

    const pageUrl = deleteSlash(payload.screenId);
    const ab_test_name: string = yield select(testaniaName);

    const otherAnalyticData: Awaited<ReturnType<typeof AnalyticUtils.getAnalyticData>> = yield call(
        AnalyticUtils.getAnalyticData,
        'purchase'
    );

    const content_id = getContentId({
        product: currentProduct,
        price: subscriptionPrice,
        trialPrice: amountValue,
    });

    const meta = {
        payment_method: Number(paymentMethod),
        order_id: order?.order_id,
        subscription_id: order?.subscription_id,
        payment_type: currentProduct?.payment_type,
        product_code: currentProduct?.product_code,
        flow_link,
        analytic_data: {
            currency: currency?.name,
            value: calculateLTV(currentProduct?.ltv, currentProduct?.period),
            content_id,
            price: amountValue,
            payment: PAYMENT_METHOD_NAME[paymentMethod as keyof typeof PAYMENT_METHOD_NAME],
            user_id: userId,
            order_id: order?.order_id,
            event_type: otherAnalyticData.event_type,
            url: otherAnalyticData.url,
            tariff: currentProduct?.id,
            order_type: currentProduct?.payment_type,
            country: otherAnalyticData.country,
            gender: otherAnalyticData.gender,
            ab_test_name: ab_test_name,
        },
        ...(ab_test_name && { ab_test_name }),
        ...(pageUrl && { payment_screen: pageUrl }),
        ...(currentProduct?.payment_type === 'subscription' && {
            subscription_period: currentProduct?.period,
            subscription_trial_period: currentProduct?.trial,
        }),
    };

    // fix of local problem with SSRF
    isLocalEnv() && replaceDataWithLocalhost(meta);

    try {
        const postInitResponse: ValidateResponse = yield api.payment.postInit(meta);

        const isPendingStatus = postInitResponse.is_pending;

        payload.data.amountWithoutCommission = calculateLTV(currentProduct?.ltv, currentProduct?.period);
        payload.data.isIntroductory = isTrial;
        payload.data.introductorySubscriptionPrice = currentProduct?.start_price;

        yield call(sendAnalyticPurchase, payload, isPendingStatus);
        handleKwaiEvent('purchase');

        yield put({ type: SET_IS_PAID, payload: true });
        if (order?.subscription_id) yield put(setSubscriptionId(order.subscription_id));
        yield put(setValidatePaymentData({ ...postInitResponse }));
        yield put(setOrderId(order?.order_id));
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
    } catch (error) {
        console.error('error', error);
        alert('Something went wrong with payment validate: ' + error);

        sentry.logError(error, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, {
            ...currentProduct,
            ...meta,
        });

        yield put(setValidatePaymentFail(error));
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
    }
}

function* sendAnalyticPurchase(payload: ValidatePayload, isPendingStatus: boolean) {
    const {
        amountWithoutCommission,
        introductorySubscriptionPrice,
        order: { amount, currency, subscription_id },
        brand,
    } = payload.data;

    const pageUrl = deleteSlash(payload.screenId);
    const product: CurrentProduct = yield select(getCurrentProduct);
    const paymentMethod: PaymentMethod = yield select(getPaymentMethod);
    const userId: number = yield select(getUserId);
    const ab_test_name: string = yield select(testaniaName);
    const urlParams = generateQueryParams();
    const priceAmount: number = currency === CLP.sign ? amount : fromPennyToInt(amount);

    const content_id = getContentId({
        product,
        price: fromPennyToInt(introductorySubscriptionPrice),
        trialPrice: priceAmount,
    });

    const options = {
        currency: currency,
        value: amountWithoutCommission,
        content_id,
        subscription_price: fromPennyToInt(introductorySubscriptionPrice),
        price: priceAmount,
        payment: PAYMENT_TYPES_NAME[paymentMethod],
        card_type: brand,
        urlParams,
        user_id: userId,
        order_id: payload?.data?.order?.order_id,
        subscription_id: subscription_id,
        screen_id: pageUrl,
        tariff: product.id,
        ab_test_name,
        order_type: product?.payment_type,
        analytics: payload.analytics || DEFAULT_ACTIVE_ANALYTICS,
    };

    isPendingStatus
        ? Main.customData('purch_pending', cleanObject(options))
        : Main.purchase(cleanObject(options), {
              ...getAnalyticsSystems({ period: product.period, trial: product.trial, analytics: options.analytics }),
          });
}

export const validatePayment = [takeLatest(actionTypes.VALIDATE_PAYMENT, validate)];

export const validatePaymentTestData = {
    validate,
    getCurrentProduct,
    getCurrency,
    getFlowLink,
    testaniaName,
    sendAnalyticPurchase,
    getPaymentMethod,
    getUserId,
};
