import { useEffect, useState } from 'react';

import config from 'config';

import api from 'api';

import { getProjectEmail } from 'helpers/utilsUpdated';

import { UserInfoResponse } from 'types/user/userApiInterface';

interface IUsePurchasedProduct {
    productCheckList?: string[];
}

const usePurchasedProduct = ({ productCheckList }: IUsePurchasedProduct = { productCheckList: [] }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleNextModal = () => {
        window.open(config.USER_CABINET_URL, '_blank');
    };

    const handleSupportModal = () => {
        window.location.href = `mailto:${getProjectEmail()}`;
    };

    useEffect(() => {
        const handleRequest = async () => {
            const response = (await api.user.getUser()) as UserInfoResponse;

            if (response.product_list) {
                const purchasedProducts = response.product_list;

                const isPurchased = productCheckList?.some((product) =>
                    Object.keys(purchasedProducts).includes(product)
                );

                if (isPurchased) {
                    setIsOpenModal(true);
                }
            }
        };

        handleRequest();
    }, []);

    return {
        isOpenModal,
        setIsOpenModal,
        handleNextModal,
        handleSupportModal,
    };
};

export { usePurchasedProduct };
