import { ThemeProvider as WikrThemeProvider } from 'wikr-core-components';
import { ReactNode } from 'react';

import { useThemeConfiguration } from 'hooks';

import themeConfig from '../../themeConfig.json';

interface ThemeProviderProps {
    children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const theme = useThemeConfiguration(themeConfig);

    return <WikrThemeProvider theme={theme}>{children}</WikrThemeProvider>;
};
