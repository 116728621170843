import classNames from 'classnames/bind';

import styles from './Loader.module.css';

import * as PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const Loader = ({ isLoading, additionalClass }) => {
    const loaderStyles = cx('loader', { [additionalClass]: additionalClass });

    return <>{isLoading && <div className={loaderStyles} />}</>;
};

Loader.propTypes = {
    isLoading: PropTypes.bool,
    additionalClass: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default Loader;
