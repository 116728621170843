import { put, putResolve, call, select, takeLatest, delay } from 'redux-saga/effects';

import { setUserEmail, signUp, setCountry } from 'redux/User/actions';
import { selectTestaniaName } from 'redux/Testania/selectors';
import { setCompleteOBs } from 'redux/Testania/actions';
import { SKIP_ONBOARDING } from 'redux/Onboadring/actionTypes';
import { skipOnboarding } from 'redux/Onboadring/actions';

import { generateRandomIntBetween } from 'helpers/combinedCodes';

function* skip({ payload }: ReturnType<typeof skipOnboarding>) {
    const generatedEmail = `few3+skipob${generateRandomIntBetween(100_000, 999_999)}@amazingapps.tech`;

    yield put(setUserEmail(generatedEmail));

    yield putResolve(
        signUp({
            toNextPage: () => console.warn('next'),
            payload: {
                date_of_birth: '1990-01-01',
                email: generatedEmail,
                gender: 'male',
                height: 180,
                weight: 80,
                fitness_level: 'beginner',
                units: 'imperial',
                target_weight: 70,
                localization: 'en',
                target_zone: [],
            },
        })
    );

    try {
        yield put(setCountry('US'));

        const completeObs: string = yield select(selectTestaniaName);

        yield put(setCompleteOBs(completeObs));

        yield delay(3000);

        yield call(payload.reload);
    } catch (error) {
        console.warn(error);
    }
}

export const skipOnBoardingSaga = [takeLatest(SKIP_ONBOARDING, skip)];
