/* eslint-disable max-lines */
export const DEFAULT_FLOW_PROJECT_NAME = 'fasting';
export const DEFAULT_EXPIRE_CONFIG_TIME = 1440;

export const DEFAULT_FLOW = {
    branch_name: 'default',
    flow_name: 'fasting__default__default_16__Default',
    start_page: {
        id: 'index',
        name: 'index',
        config: {},
    },
    onboarding_flow: [
        {
            id: 'ob_body_type_current_mix',
            name: 'ob_body_type_current_mix',
            config: {},
        },
        {
            id: 'ob_body_type_target_mix',
            name: 'ob_body_type_target_mix',
            config: {},
        },
        {
            id: 'ob_goals_mix_b',
            name: 'ob_goals_mix_b',
            config: {},
        },
        {
            id: 'ob_social_proof_mix',
            name: 'ob_social_proof_mix',
            config: {},
        },
        {
            id: 'ob_target_zones_mix',
            name: 'ob_target_zones_mix',
            config: {},
        },
        {
            id: 'ob_happy_weight',
            name: 'ob_happy_weight',
            config: {},
        },
        {
            id: 'ob_time_breakfast',
            name: 'ob_time_breakfast',
            config: {},
        },
        {
            id: 'ob_time_lunch',
            name: 'ob_time_lunch',
            config: {},
        },
        {
            id: 'ob_time_dinner',
            name: 'ob_time_dinner',
            config: {},
        },
        {
            id: 'ob_pref_eat_place',
            name: 'ob_pref_eat_place',
            config: {},
        },
        {
            id: 'ob_weekend',
            name: 'ob_weekend',
            config: {},
        },
        {
            id: 'ob_workout_mix',
            name: 'ob_workout_mix',
            config: {},
        },
        {
            id: 'ob_job_load',
            name: 'ob_job_load',
            config: {},
        },
        {
            id: 'ob_job_active',
            name: 'ob_job_active',
            config: {},
        },
        {
            id: 'ob_tags_a',
            name: 'ob_tags_a',
            config: {},
        },
        {
            id: 'ob_stairs',
            name: 'ob_stairs',
            config: {},
        },
        {
            id: 'ob_walking',
            name: 'ob_walking',
            config: {},
        },
        {
            id: 'ob_water',
            name: 'ob_water',
            config: {},
        },
        {
            id: 'ob_sleep',
            name: 'ob_sleep',
            config: {},
        },
        {
            id: 'ob_health_conditions',
            name: 'ob_health_conditions',
            config: {},
        },
        {
            id: 'ob_medications',
            name: 'ob_medications',
            config: {},
        },
        {
            id: 'ob_back_issue_config',
            name: 'ob_back_issue_config',
            config: {
                disclaimer: 'true',
                disclaimerText: 'onboardingFlow.obBackIssueConfig.disclaimer',
                title: 'onboardingFlow.obBackIssue.title',
            },
        },
        {
            id: 'ob_creating_3',
            name: 'ob_creating_3',
            config: {},
        },
        {
            id: 'ob_trust_feedback',
            name: 'ob_trust_feedback',
            config: {},
        },
        {
            id: 'ob_bad_habits',
            name: 'ob_bad_habits',
            config: {},
        },
        {
            id: 'ob_fasting_awareness',
            name: 'ob_fasting_awareness',
            config: {},
        },
        {
            id: 'ob_motivation',
            name: 'ob_motivation',
            config: {},
        },
        {
            id: 'ob_height_a',
            name: 'ob_height_a',
            config: {},
        },
        {
            id: 'ob_weight_current_a',
            name: 'ob_weight_current_a',
            config: {},
        },
        {
            id: 'ob_weight_target_a',
            name: 'ob_weight_target_a',
            config: {},
        },
        {
            id: 'ob_age_a',
            name: 'ob_age_a',
            config: {},
        },
        {
            id: 'feedback_summary',
            name: 'feedback_summary',
            config: {},
        },
        {
            id: 'ob_occasion',
            name: 'ob_occasion',
            config: {},
        },
        {
            id: 'ob_occasion_result',
            name: 'ob_occasion_result',
            config: {},
        },
        {
            id: 'creating_d',
            name: 'creating_d',
            config: {},
        },
        {
            id: 'email_config_a',
            name: 'email_config_a_5',
            config: {
                screenSubtitleVisible: 'false',
                facebookLogin: 'false',
                disclaimerStateVisible: 'true',
                disclaimerState: 'onboardingFlow.emailConfigA.disclaimer.var1',
                buttonText: 'onboardingFlow.emailConfig.button.var1',
                feedbackLabel: 'false',
                feedbackBadge: 'true',
                title: 'onboardingFlow.emailConfig.title.var4',
            },
        },
        {
            id: 'email_consent_config_a',
            name: 'email_consent_config_a_1',
            config: {
                title: 'onboardingFlow.emailConsent.title',
                checkboxesAmount: '1',
            },
        },
        {
            id: 'result',
            name: 'result',
            config: {},
        },
        {
            id: 'ob_welcome_to_fe',
            name: 'ob_welcome_to_fe',
            config: {},
        },
    ],
    payment_flow: [
        {
            id: 'upsell_2',
            name: 'upsell_2',
            parent_id: 'offer_a5_foxtrot16_config',
            is_paid: true,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd',
                    name: 'fat_burning_gd__weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'fat_burning_gd__weight_loss_gd',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 1,
                    price_per_state: 699,
                    price: 699,
                    start_price: 1500,
                    ltv: 499,
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd',
                    name: 'weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'weight_loss_gd',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 1,
                    price_per_state: 499,
                    price: 499,
                    start_price: 1000,
                    ltv: 357,
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd',
                    name: 'fat_burning_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'fat_burning_gd',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 1,
                    price_per_state: 499,
                    price: 499,
                    start_price: 1000,
                    ltv: 357,
                },
            ],
            config: {},
        },
        {
            id: 'vip_support',
            name: 'vip_support',
            parent_id: 'upsell_2',
            is_paid: true,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'subscription',
                    id: '4071853f-eaf9-4988-bbb8-6bd9ab872e53',
                    name: 'One-month (0.99)',
                    trial: 0,
                    period: 31,
                    product_code: 'vip_support',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 99,
                    price_per_state: 99,
                    price: 99,
                    start_price: 99,
                    ltv: 141,
                },
            ],
            config: {},
        },
        {
            id: 'vip_support',
            name: 'vip_support',
            parent_id: 'upsell_2',
            is_paid: false,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'subscription',
                    id: '4071853f-eaf9-4988-bbb8-6bd9ab872e53',
                    name: 'One-month (0.99)',
                    trial: 0,
                    period: 31,
                    product_code: 'vip_support',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 99,
                    price_per_state: 99,
                    price: 99,
                    start_price: 99,
                    ltv: 141,
                },
            ],
            config: {},
        },
        {
            id: 'vip_support',
            name: 'vip_support',
            parent_id: 'upsell_2',
            is_paid: true,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'subscription',
                    id: '4071853f-eaf9-4988-bbb8-6bd9ab872e53',
                    name: 'One-month (0.99)',
                    trial: 0,
                    period: 31,
                    product_code: 'vip_support',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 99,
                    price_per_state: 99,
                    price: 99,
                    start_price: 99,
                    ltv: 141,
                },
            ],
            config: {},
        },
        {
            id: 'vip_support',
            name: 'vip_support',
            parent_id: 'upsell_2',
            is_paid: false,
            skip_checkout: true,
            products: [
                {
                    payment_type: 'subscription',
                    id: '4071853f-eaf9-4988-bbb8-6bd9ab872e53',
                    name: 'One-month (0.99)',
                    trial: 0,
                    period: 31,
                    product_code: 'vip_support',
                    is_preselected: false,
                    product_state: 'per_month',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 99,
                    price_per_state: 99,
                    price: 99,
                    start_price: 99,
                    ltv: 141,
                },
            ],
            config: {},
        },
        {
            id: 'upsell_2',
            name: 'upsell_2',
            parent_id: 'payment_g5_foxtrot16_config',
            is_paid: true,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd__weight_loss_gd',
                    name: 'fat_burning_gd__weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'fat_burning_gd__weight_loss_gd',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 1,
                    price_per_state: 699,
                    price: 699,
                    start_price: 1500,
                    ltv: 499,
                },
                {
                    payment_type: 'lifetime',
                    id: 'weight_loss_gd',
                    name: 'weight_loss_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'weight_loss_gd',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 1,
                    price_per_state: 499,
                    price: 499,
                    start_price: 1000,
                    ltv: 357,
                },
                {
                    payment_type: 'lifetime',
                    id: 'fat_burning_gd',
                    name: 'fat_burning_gd',
                    trial: 0,
                    period: 1,
                    product_code: 'fat_burning_gd',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 1,
                    price_per_state: 499,
                    price: 499,
                    start_price: 1000,
                    ltv: 357,
                },
            ],
            config: {},
        },
        {
            id: 'offer_a5_foxtrot16_config',
            name: 'offer_a5_foxtrot16_config_apple',
            parent_id: 'payment_g5_foxtrot16_config',
            is_paid: false,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'ab90f9ca-d365-4468-887a-51b01201e78e',
                    name: 'One-week intro (5.50) + One-month (30.99)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 150,
                    price_per_state: 79,
                    price: 550,
                    start_price: 1049,
                    ltv: 2665,
                },
                {
                    payment_type: 'subscription',
                    id: '3af9e081-b45d-401e-b858-c6a68e153467',
                    name: 'One-month intro (11.99) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 100,
                    price_per_state: 39,
                    price: 1199,
                    start_price: 3099,
                    ltv: 3293,
                },
                {
                    payment_type: 'subscription',
                    id: 'eb667562-a647-4ee7-bd72-b591148dd0d9',
                    name: 'Three-month intro (21.49) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 59,
                    price_per_state: 24,
                    price: 2149,
                    start_price: 5319,
                    ltv: 4956,
                },
                {
                    payment_type: 'subscription',
                    id: 'f9b1784e-2f7c-434d-90ce-8b2adfa65e68',
                    name: 'One-week intro (10.49) + One-month (30.99)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 0,
                    price_per_state: 150,
                    price: 1049,
                    start_price: 1049,
                    ltv: 3141,
                },
                {
                    payment_type: 'subscription',
                    id: 'ee4ac51e-17c6-4e61-a565-8c5a39f15bdd',
                    name: 'One-month intro (30.99) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 0,
                    price_per_state: 100,
                    price: 3099,
                    start_price: 3099,
                    ltv: 4872,
                },
                {
                    payment_type: 'subscription',
                    id: 'f76ef55c-8026-422a-bbc9-c2b5d0442670',
                    name: 'Three-month intro (53.19) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 0,
                    price_per_state: 59,
                    price: 5319,
                    start_price: 5319,
                    ltv: 6841,
                },
            ],
            config: {
                mainImageTitle: 'paymentFlow.offerA4Foxtrot16Config.mainImageTitle.var1',
                trialFullPrice: '30.99',
                paymentMethod: 'apple_pay',
                withFullPrice: 'on',
            },
        },
        {
            id: 'payment_g5_foxtrot16_config',
            name: 'payment_g5_foxtrot16_config_apple',
            parent_id: null,
            is_paid: null,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'c714c542-4373-41c9-b247-209815223772',
                    name: 'One-week intro (6.93) + One-month (30.99)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 150,
                    price_per_state: 99,
                    price: 693,
                    start_price: 1049,
                    ltv: 3126,
                },
                {
                    payment_type: 'subscription',
                    id: '9ea6bce5-34fe-4ee3-93d9-4ea5f455bc37',
                    name: 'One-month intro (15.19) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 100,
                    price_per_state: 49,
                    price: 1519,
                    start_price: 3099,
                    ltv: 4584,
                },
                {
                    payment_type: 'subscription',
                    id: '7ad6132e-71fc-47c9-9e82-dcea25af4ac3',
                    name: 'Three-month intro (25.99) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 59,
                    price_per_state: 29,
                    price: 2599,
                    start_price: 5319,
                    ltv: 4936,
                },
                {
                    payment_type: 'subscription',
                    id: 'f9b1784e-2f7c-434d-90ce-8b2adfa65e68',
                    name: 'One-week intro (10.49) + One-month (30.99)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 0,
                    price_per_state: 150,
                    price: 1049,
                    start_price: 1049,
                    ltv: 3141,
                },
                {
                    payment_type: 'subscription',
                    id: 'ee4ac51e-17c6-4e61-a565-8c5a39f15bdd',
                    name: 'One-month intro (30.99) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 0,
                    price_per_state: 100,
                    price: 3099,
                    start_price: 3099,
                    ltv: 4872,
                },
                {
                    payment_type: 'subscription',
                    id: 'f76ef55c-8026-422a-bbc9-c2b5d0442670',
                    name: 'Three-month intro (53.19) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'US',
                    start_price_per_state: 0,
                    price_per_state: 59,
                    price: 5319,
                    start_price: 5319,
                    ltv: 6841,
                },
            ],
            config: {
                femaleModelImageBefore:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/monetisation/female_before.webp',
                femaleModelImageAfter:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/monetisation/female_after.webp',
                maleModelImageBefore:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/monetisation/male_before_3.webp',
                maleModelImageAfter:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/FE(w)/monetisation/male_after_3.webp',
                statisticLabel: 'fasting',
                plateIllustration: 'on',
                socialProofText: 'paymentFlow.paymentG4Foxtrot16Config.socialProof.var1',
                cardsTitle: 'paymentFlow.paymentG4Foxtrot16Config.cardsTitle.var1',
                trialFullPrice: '30.99',
                paymentMethod: 'apple_pay',
                withFullPrice: 'on',
            },
        },
    ],
    expire: 1698385132511,
};
