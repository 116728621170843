import { Theme } from 'wikr-core-components';
import { useState } from 'react';

import { getFaviconElement } from 'helpers/utilsUpdated';

interface ThemeConfigScheme {
    inlineStyle: object;
    themeStyle: object;
    faviconUrl: string;
    logoUrl: string;
}

export const useThemeConfiguration = (themeConfig: Record<string, ThemeConfigScheme>): Theme => {
    const [theme, setTheme] = useState<Theme>();

    if (!theme) {
        const hostName = window.location.host;

        const { themeStyle, faviconUrl } = themeConfig?.[hostName] || themeConfig['default'];

        const faviconElement = getFaviconElement() as HTMLLinkElement;

        faviconElement.href = faviconUrl;

        setTheme(themeStyle as Theme);
    }

    return theme ?? (themeConfig['default'].themeStyle as Theme);
};
