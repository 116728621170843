/* eslint-disable max-lines */
import { Main } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_PAYMENT, SOLID_ERROR_CODES } from 'sentry-utils';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { getErrorReasonGroup } from 'helpers/sentryErrorReasonGroup';
import { ClientSdkInstance, SdkMessage } from '@solidgate/react-sdk';
import { MessageType } from '@solidgate/client-sdk-loader';

import {
    changeExclusiveOfferState,
    setHideCustomPaymentButton,
    setIsApplePayAvailable,
    setIsGooglePayAvailable,
    setLoadingStatus,
    setPaymentErrorCode,
} from 'redux/Payment/actions';

import { PAYMENT_FORM_INIT_TIMEOUT } from 'constants/timeoutValues';
import { BANK_CARD_CUSTOM_BUTTON_ID, PAYMENT_TYPES_NAME_ID } from 'constants/payments';

import sentry from 'services/Sentry/SentryInstance';
import { Tags } from 'services/Sentry/interfaces';

import { useValidatePayment } from 'hooks/index';

import { PAYMENT_EVENT_NAMES } from 'helpers/paymentMethods/config';

import { CustomTagErrorMessage } from '../types';

import { IUseSolidHandlers } from 'interfaces/Payments/paymentMethods';

const INSUFFICIENT_FUNDS_ERROR_CODE = '3.02';
const PAYMENT_METHOD_CARD = 1;

export function useSolidHandlers({
    currentProduct,
    paymentData,
    initPaymentGenerator,
    setHasPaymentError,
    setHasPaymentSuccess,
}: IUseSolidHandlers) {
    const dispatch = useDispatch();
    const [isProcessing, setIsProcessing] = useState(false);

    const [cardBrand, setCardBrand] = useState<string | null>(null);

    const validatePaymentHandler = useValidatePayment();

    const getErrorCustomTags = (errorData: SdkMessage[MessageType.Error] | SdkMessage[MessageType.Fail]) => {
        const error = errorData?.type === MessageType.Error ? errorData?.details : errorData;

        let customTags: Tags | undefined;

        const errorCode = error?.code;
        const errorMessage = error?.message;

        const getErrorMessage = (message: CustomTagErrorMessage) =>
            typeof message === 'string' ? message : message[0];

        if (errorCode) {
            const reason = getErrorReasonGroup(errorCode);

            customTags = [
                ['SOLID_ERROR_CODE', errorCode],
                ['reason', reason],
            ];
        }

        if (errorMessage) {
            customTags = customTags
                ? [...customTags, ['SOLID_ERROR_MESSAGE', getErrorMessage(errorMessage)]]
                : [['SOLID_ERROR_MESSAGE', getErrorMessage(errorMessage)]];
        }

        return customTags;
    };

    const handleOnError = (error: SdkMessage[MessageType.Error]) => {
        setIsProcessing(false);

        const errorCode = error?.details?.code;

        if (errorCode) {
            dispatch(
                setPaymentErrorCode({
                    error_code: errorCode,
                    // @ts-ignore
                    product_name: currentProduct?.name,
                    currency: paymentData.currency,
                    // @ts-ignore
                    product_price: currentProduct?.price,
                })
            );
        }

        const customTags = getErrorCustomTags(error);

        if (errorCode && SOLID_ERROR_CODES.includes(errorCode)) {
            sentry.logError(
                error,
                SENTRY_PAYMENT,
                ERROR_LEVELS.CRITICAL,
                {
                    ...(currentProduct as object),
                    ...paymentData,
                },
                customTags
            );
        }

        const codeErrorList = [INSUFFICIENT_FUNDS_ERROR_CODE];

        if (codeErrorList.includes(`${errorCode}`)) {
            dispatch(changeExclusiveOfferState(true));
        }

        setTimeout(() => {
            dispatch(setHideCustomPaymentButton(false));

            initPaymentGenerator();
        }, PAYMENT_FORM_INIT_TIMEOUT);
    };

    const handleOnFail = (error: SdkMessage[MessageType.Fail]) => {
        const customTags = getErrorCustomTags(error);
        const errorCode = error?.code;

        dispatch(setHideCustomPaymentButton(true));

        setIsProcessing(false);

        if (errorCode) {
            dispatch(
                setPaymentErrorCode({
                    error_code: errorCode,
                    product_name: currentProduct?.name,
                    currency: paymentData.currency,
                    product_price: currentProduct?.price,
                })
            );
        }

        setHasPaymentError(true);

        const codeErrorList = [INSUFFICIENT_FUNDS_ERROR_CODE];

        if (codeErrorList.includes(`${errorCode}`)) {
            setTimeout(() => {
                dispatch(changeExclusiveOfferState(true));
            }, PAYMENT_FORM_INIT_TIMEOUT);
        }

        if (errorCode && SOLID_ERROR_CODES.includes(errorCode)) {
            sentry.logError(
                error,
                SENTRY_PAYMENT,
                ERROR_LEVELS.CRITICAL,
                {
                    ...currentProduct,
                    ...paymentData,
                },
                customTags
            );
        }

        setTimeout(() => {
            dispatch(setHideCustomPaymentButton(false));

            initPaymentGenerator();
        }, PAYMENT_FORM_INIT_TIMEOUT);
    };

    const handleOnSuccess = (data: SdkMessage[MessageType.Success]) => {
        dispatch(setHideCustomPaymentButton(true));

        setHasPaymentSuccess(true);

        setIsProcessing(false);

        validatePaymentHandler(PAYMENT_TYPES_NAME_ID.CREDIT_CARD, PAYMENT_METHOD_CARD, {
            ...data,
            ...(cardBrand && { brand: cardBrand }),
        });
    };

    const handleOnSubmit = (event: SdkMessage[MessageType.Submit]) => {
        Main.customData('processor_form__click', {
            payment: PAYMENT_EVENT_NAMES[event.entity],
            event_label: PAYMENT_EVENT_NAMES[event.entity],
        });

        setIsProcessing(true);

        dispatch(setLoadingStatus(true));
    };

    const handleCard = (e: SdkMessage[MessageType.Card]) => {
        setCardBrand(e.card.brand);
    };

    const handleOnReadyPaymentInstance = (form: ClientSdkInstance) => {
        dispatch(setHideCustomPaymentButton(false));

        setIsProcessing(false);

        const customPaymentButton = document.getElementById(BANK_CARD_CUSTOM_BUTTON_ID);

        if (customPaymentButton) {
            customPaymentButton.onclick = () => {
                if (isProcessing) return;

                form?.submit();
            };
        }

        Main.customData('processor_form__load', { payment: 'bank_card', event_label: 'bank_card' });
    };

    const handleOnMounted = (event: SdkMessage[MessageType.Mounted]) => {
        if (event.type === 'mounted') {
            if (event.entity === 'applebtn') {
                dispatch(setIsApplePayAvailable(true));
            }

            if (event.entity === 'googlebtn') {
                dispatch(setIsGooglePayAvailable(true));
            }
        }
    };

    return {
        isProcessing,
        handleOnError,
        handleOnFail,
        handleOnSuccess,
        handleOnSubmit,
        handleCard,
        handleOnReadyPaymentInstance,
        handleOnMounted,
    };
}
