import { lazyLoad, lazyWithRetry } from 'helpers/lazyWithRetry';

export const pathWithRegularExpression = (path: string) => `/:language([a-z]{2})?/${path}`;

export const generateRoute = (route: string, pathToFile: string) => {
    const isDevEnv = process.env.NODE_ENV === 'development';

    const PageComponent = isDevEnv
        ? lazyWithRetry(() => import(/* webpackChunkName: "[request]" */ `../pages/${pathToFile}`))
        : lazyLoad({ importer: () => import(/* webpackChunkName: "[request]" */ `../pages/${pathToFile}`) });

    return {
        path: pathWithRegularExpression(route),
        key: route,
        render: (props: any) => <PageComponent key={pathToFile} {...props} />,
    };
};

export const PreloadTestania = lazyLoad({
    importer: () => import(/* webpackChunkName: "[request]" */ '../pages/GeneralPages/PreloaderTestania'),
});
